<template>
  <div class="topbar">
    <v-menu content-class="topbar__dropdown" offset-y nudge-bottom="16">
      <template v-slot:activator="{ on, attrs }">
        <div class="account__circle" v-bind="attrs" v-on="on">
          {{ nameLabel }}
        </div>
      </template>

      <div v-if="auth.isParkmanAdmin()" class="card-container">
        <div class="card-container">
          <div class="card-item first-row">
            <div class="card-title">{{ $t('title.open_admin') }}</div>
          </div>

          <div class="card-item" @click="openPayouts">
            <div class="card-title">{{ $t('title.payouts') }}</div>
          </div>

          <div class="card-item" @click="openSupportUsers">
            <div class="card-title">{{ $t('title.support_users') }}</div>
          </div>

          <div class="card-item logout-card" @click="auth.logout()">
            <div class="card-title">{{ $t('logout') }}</div>
            <div class="card-subtitle">
              {{ userInfo ? userInfo.email : '' }}
            </div>
          </div>

          <div class="card-item" @click="switchAdminRole">
            <div class="card-title">
              Switch Admin role (for testing purposes)
            </div>
          </div>
        </div>
      </div>
      <div v-else class="card-container-merchant">
        <div class="card-item-merchant" @click="auth.logout()">
          <div class="card-title-merchant">{{ $t('logout') }}</div>
          <div class="card-subtitle-merchant">
            {{ userInfo ? userInfo.email : 'super.admin@parkman.io' }}
          </div>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import auth from '@/auth'
import helpers from '@/helpers'
import eventBus from '@/helpers/eventBus'
import * as config from '@/config/config'

export default {
  name: 'TopBar',
  data() {
    return {
      auth: auth
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userData
    },
    nameLabel() {
      const userInfo = this.$store.state.userData

      const username = userInfo
        ? `${userInfo.first_name} ${userInfo.last_name}`
        : 'UU'
      if (username) {
        return username
          .split(' ')
          .slice(0, 2)
          .map((word) => word.charAt(0))
          .join('')
          .toUpperCase()
      }

      return '??'
    }
  },
  methods: {
    async fetchUserData() {
      const userData = helpers.fetchStorage('user-data')
      if (userData) {
        await this.$store.dispatch('setUserData', userData)
      }
    },
    switchAdminRole() {
      const hasRequiredRoles =
        this.auth.hasRole('merchant_admin') &&
        this.auth.hasRole('parkman_admin')

      if (!hasRequiredRoles) {
        this.$store.dispatch('setSystemMessage', {
          type: 'error',
          message:
            "You must have both 'merchant_admin' and 'parkman_admin' roles to switch them"
        })
        return
      }

      this.auth.switchAdminRole()

      this.$store.dispatch('setSystemMessage', {
        type: 'success',
        message: 'Refresh to activate the change'
      })
    },
    openSupportUsers() {
      this.$router.push({ name: 'SupportUsers' })
    },
    async openPayouts() {
      if (this.$route.name === 'AllMerchants') {
        eventBus.$emit('openPayouts')
      } else {
        await this.$store.dispatch('setSidebar', config.SIDEBAR.MERCHANT_ALL)
        await this.$router.push({ name: 'AllMerchants', query: { tab: 1 } })
      }
    }
  },
  async mounted() {
    if (this.$store.state.userData === null) {
      await this.fetchUserData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
.topbar__control {
  width: 100%;
  text-align: right;
  padding-right: 8px;
  border-right: 1px solid $color-line;
}

.control__add {
  color: $color-brand;
  font-size: 28px;
  margin-top: 1px;
}
</style>

<style lang="scss" scoped>
@import '@/style/common';
@import '@/style/variables';

.topbar {
  justify-content: flex-end;
  position: absolute;
  top: 16px;
  right: 42px;

  .account__circle {
    width: 32px;
    height: 32px;
    background: $color-gray-background;
    color: $text-color-light;
    line-height: 30px;
    border: 2px solid $color-line;
    border-radius: 50%;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
  }
}

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topbar__dropdown {
  right: 16px;
  left: unset !important;
}
.container {
  width: 197px;
  height: 46px;
  padding: 8px 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  &.theme--light.v-list-item:not(.v-list-item--active):not(
      .v-list-item--disabled
    ) {
    color: $text-color-light !important;
  }
}
</style>
<style lang="scss">
.card-container {
  width: 303px;
  background-color: white; /* Add white background color */
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .first-row {
    border-bottom: 1px solid #e5e5e5;
    &:hover {
      background-color: transparent !important;
    }
  }
  .card-item {
    cursor: pointer;
    align-self: stretch;
    height: 40px;
    padding: 8px 16px;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    display: inline-flex;

    .card-title {
      flex: 1 1 0;
      text-align: right;
      color: #333333;
      font-size: 12px;
      font-family: Roboto;
      font-weight: 400;
      line-height: 15.6px;
      word-wrap: break-word;
    }

    .card-subtitle {
      color: rgba(31, 31, 31, 0.5);
      font-size: 10px;
      font-family: Roboto;
      font-weight: 500;
      line-height: 12px;
      word-wrap: break-word;
    }

    &:hover {
      background-color: #f6f6f6;
    }
  }

  /* Additional styles for the Logout card */
  .logout-card {
    height: 46px;
    padding: 8px 16px;
    background-color: white;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 2px;
    display: flex;

    .card-title {
      text-align: right;
      color: #333333;
      font-size: 12px;
      font-family: Roboto;
      font-weight: 400;
      line-height: 15.6px;
      word-wrap: break-word;
    }
  }
}
</style>

<style lang="scss">
.card-container-merchant {
  height: 46px;
  background-color: white;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .card-item-merchant {
    cursor: pointer;
    align-self: stretch;
    height: 46px;
    padding: 8px 16px;
    background-color: white;
    border: 0.5px rgba(55, 189, 1, 0.1) solid;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    display: flex;
    border-radius: 4px;

    .card-title-merchant {
      align-self: stretch;
      text-align: right;
      color: #333333;
      font-size: 12px;
      font-family: Roboto;
      font-weight: 400;
      line-height: 15.6px;
      word-wrap: break-word;
    }

    .card-subtitle-merchant {
      align-self: stretch;
      text-align: right;
      color: rgba(31, 31, 31, 0.5);
      font-size: 10px;
      font-family: Roboto;
      font-weight: 500;
      line-height: 12px;
      word-wrap: break-word;
    }

    &:hover {
      background-color: #f6f6f6;
    }
  }
}
</style>
