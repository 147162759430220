/**
 * Configuration constants
 */

// mobile screen maximum width in pixel
export const SMALL_SCREEN_BREAKPOINT = 767
// large screen width breakpoint
export const LARGE_SCREEN_BREAKPOINT = 1440

export const SIDEBAR = {
  MERCHANT: 0,
  MERCHANT_ALL: 1,
  MERCHANT_CHOOSE: 2
}
