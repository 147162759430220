import Vue from 'vue'
import Vuex from 'vuex'
import helpers from '@/helpers'
import * as config from '@/config/config'
import { CurrencyTypesEnum } from '@/enums'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isPageReady: true,
    systemMessage: [],
    currentAnonymousView: null,
    currentLoginEmail: '', // used to pass the typed email to forget-password view
    signupData: null, // used to pass signup data between emails and phone view
    companyData: null, // huge company info object returned by backend,
    merchants: [], // list of merchants which user has access to,
    allLocations: [], // all locations,
    filteredLocations: [], // filtered locations,
    groupData: null, // list of company's group
    vehicleData: null, // list of company's vehicle, groupped by payment groups
    selectedGroup: null, // the data of selected group, used across views
    userData: {
      id: null,
      account_id: null,
      email: null,
      first_name: null,
      last_name: null
    }, // logged-in user data
    employeeData: null, // list of company's employee
    pricingData: null, // list of company's available pricing packages
    roleOptions: [], // role list
    accountRoleOptions: [], // role list
    merchantAccounts: [], // merchant accounts list
    updateTimestamp: null, // hack to force the component to update when Vue component is not reactive as expected
    paymentOptions: null, // supported payment methods - defined by country
    loginIdentifier: '', // identifier (phone / email) user used to login - used to prefill input box in case user want to re-edit
    companyCreatorPackage: null, // use to "save" the package chosen by copmany creator in the signup flow
    currentCompanyId: null, // use to know the company which is used in the home page
    userCompanies: null, // all companies which user belongs
    isNewDashboardDataRequest: false, // Dashboard need to reload new data
    sidebarShowing: true,
    sidebar: config.SIDEBAR.MERCHANT_ALL, // current state of sidebar

    // merchant-related data
    merchant: null,
    isAddingLocation: false,
    isAddingMerchant: false,
    isShowingAllMerchants: false,
    currentProduct: null,
    currentProductSettings: {},
    currentProductCurrencySymbol: '',
    productInstructions: [],
    countries: [],
    showOverlay: false,
    addMerchant: {
      country_id: null,
      name: '',
      business_id: '',
      contact_email: '',
      merchant_type_id: null,
      logo: null
    },
    currentMerchant: null,
    currentLocation: null,
    isAddMerchantFormChanged: false,
    selectedMerchantId: null,
    locations: [],
    isProductInstructionFormChanged: false,
    isAddLocationFormChanged: false,
    isUpdatingLocation: false,
    timeUnitOptions: [
      {
        text: 'hour',
        value: 'hour'
      },
      {
        text: 'min',
        value: 'minute'
      },
      {
        text: 'sec',
        value: 'second'
      }
    ],
    timeDayUnitOptions: [
      {
        text: 'day',
        value: 'day'
      },
      {
        text: 'hour',
        value: 'hour'
      },
      {
        text: 'min',
        value: 'minute'
      },
      {
        text: 'sec',
        value: 'second'
      }
    ],
    searchTabsShowing: true,
    licencePlateParkingsList: [],
    searchByLicenceStartDate: null,
    searchByLicenceEndDate: null,
    showLoadMoreLicencePlatesBtn: false,
    reportTemplates: null,
    frequencyTypes: null
  },
  getters: {
    companyId: (state) => {
      return state.companyData ? state.companyData.id : null
    },
    locations: (state) => state.locations,
    currentLocation: (state) => state.currentLocation,
    merchants: (state) => state.merchants,
    allLocations: (state) => state.allLocations,
    filteredLocations: (state) => state.filteredLocations,
    countries: (state) => state.countries,
    currentMerchant: (state) => state.currentMerchant,
    currentMerchantCountry: (state) =>
      state.countries.find(
        (country) => country.id === state.currentMerchant.country_id
      ),
    addMerchant: (state) => state.addMerchant,
    roleOptions: (state) => state.roleOptions,
    productInstructions: (state) => state.productInstructions,
    currentProduct: (state) => state.currentProduct,
    currentProductSettings: (state) => state.currentProductSettings,
    currentProductCurrencySymbol: (state) => state.currentProductCurrencySymbol,
    accountRoleOptions: (state) => state.accountRoleOptions,
    userData: (state) => state.userData,
    userName: (state) => {
      return state.userData
        ? `${state.userData.first_name || ''} ${
            state.userData.last_name || ''
          }`.trim()
        : ''
    },
    // Get group account id of selected group, use as input for non-refactored endpoints
    selectedGroupAccountId: (state) => {
      if (!state.selectedGroup || !state.groupData) {
        return null
      }

      const group = state.groupData.find(
        (group) => group.id === state.selectedGroup
      )

      return group ? group.account_id : null
    },
    companyActivePricingPackages: (state) => {
      if (!state.pricingData) {
        return null
      }

      return state.pricingData.filter(
        (item) => item.is_active && !item.is_deleted
      )
    },
    companyDefaultPackage: (state) => {
      if (!state.pricingData) {
        return null
      }

      return state.pricingData.find((item) => item.is_default)
    },
    currentCompanyName: (state) => {
      if (state.userCompanies) {
        const company = state.userCompanies.find(
          (item) => item.id.toString() === state.currentCompanyId
        )

        return company ? company.name : ''
      }

      return ''
    },
    currentSidebar: (state) => {
      return state.sidebar
    },
    timeUnitOptions: (state) => state.timeUnitOptions,
    timeDayUnitOptions: (state) => state.timeDayUnitOptions,
    showSearchTabs: (state) => state.searchTabsShowing,
    searchedByLicencePlateList: (state) => state.licencePlateParkingsList,
    searchByLicenceStartDate: (state) => state.searchByLicenceStartDate,
    searchByLicenceEndDate: (state) => state.searchByLicenceEndDate,
    loadMoreLicencePlatesBtnState: (state) =>
      state.showLoadMoreLicencePlatesBtn,
    isAddingLocation: (state) => state.isAddingLocation,
    reportTemplates: (state) => state.reportTemplates,
    frequencyTypes: (state) => state.frequencyTypes
  },
  mutations: {
    SET_SHOW_OVERLAY(state, data) {
      state.showOverlay = data
    },
    SET_SELECTED_MERCHANT_ID(state, data) {
      state.selectedMerchantId = data
    },
    SET_PAGE_READY_STATUS(state, isReady) {
      state.isPageReady = isReady
    },
    SET_SYSTEM_MESSAGE(state, message) {
      if (!message) {
        state.systemMessage = []
        return
      }
      const availableTypes = ['success', 'error', 'warning', 'delete']

      if (typeof message === 'object') {
        if (message.once) {
          const messageExists = state.systemMessage.find(
            (i) => i.message === message.message
          )
          if (!messageExists) {
            state.systemMessage.push({
              message: message.message,
              type: availableTypes.includes(message.type)
                ? message.type
                : 'error'
            })
          }
        } else {
          state.systemMessage.push({
            message: message.message,
            type: availableTypes.includes(message.type) ? message.type : 'error'
          })
        }
      } else {
        state.systemMessage.push({ message: message, type: 'error' })
      }

      // v-snackbar default timeout behavior is buggy. Use this custom fix instead
      setTimeout(() => {
        state.systemMessage = []
      }, 10000)
    },
    SET_ANONYMOUS_VIEW(state, component) {
      state.currentAnonymousView = component
    },
    SET_SIGNUP_DATA(state, data) {
      state.signupData = data
    },
    SET_COMPANY_DATA(state, data) {
      state.companyData = data
    },
    SET_MERCHANTS_DATA(state, data) {
      state.merchants = data
    },
    SET_ALL_LOCATIONS_DATA(state, data) {
      state.allLocations = data
    },
    SET_FILTERED_LOCATIONS_DATA(state, data) {
      state.filteredLocations = data
    },
    CLEAR_FILTERED_LOCATIONS_DATA(state, data) {
      state.filteredLocations = []
    },
    RESET_ADD_MERCHANT_DATA(state) {
      state.addMerchant = {
        country_id: null,
        name: '',
        business_id: '',
        contact_email: '',
        merchant_type_id: null,
        logo: null
      }
    },
    SET_GROUP_DATA(state, data) {
      state.groupData = data
    },
    SET_VEHICLE_DATA(state, data) {
      state.vehicleData = data
    },
    SET_EMPLOYEE_DATA(state, data) {
      state.employeeData = data
    },
    SET_PRICING_DATA(state, data) {
      state.pricingData = data
    },
    SET_ROLE_OPTIONS(state, data) {
      state.roleOptions = data
    },
    SET_ACCOUNT_ROLE_OPTIONS(state, data) {
      state.accountRoleOptions = data
    },
    SET_MERCHANT_ACCOUNTS(state, data) {
      state.merchantAccounts = data
    },
    SET_USER_DATA(state, data) {
      state.userData = data
    },
    SET_SELECTED_GROUP(state, data) {
      state.selectedGroup = data
    },
    SET_PAYMENT_OPTIONS(state, data) {
      state.paymentOptions = data
    },
    SET_LOGIN_IDENTIFIER(state, data) {
      state.loginIdentifier = data
    },
    CLEAR_COMPANY_DATA(state) {
      state.companyData = null
      state.groupData = null
      state.paymentOptions = null
      state.employeeData = null
      state.pricingData = null
      state.vehicleData = null
      state.selectedGroup = null
      state.roleOptions = null
    },
    SET_CURRENT_COMPANY_ID(state, data) {
      state.currentCompanyId = data
    },
    SET_USER_COMPANIES(state, data) {
      state.userCompanies = data
    },
    SET_REQUEST_NEW_DASHBOARD_DATA(state, data) {
      state.isNewDashboardDataRequest = data
    },

    SHOW_SIDEBAR(state) {
      state.sidebarShowing = true
    },
    HIDE_SIDEBAR(state) {
      state.sidebarShowing = false
    },
    SET_SIDEBAR(state, sidebar) {
      state.sidebar = sidebar
    },

    SET_MERCHANT(state, data) {
      state.merchant = data
    },

    CLEAR_MERCHANT_DATA(state) {
      state.merchant = null
    },

    SET_LOCATION_ADDING_STATUS(state, isAddingLocation) {
      state.isAddingLocation = isAddingLocation
    },

    SET_MERCHANT_ADDING_STATUS(state, isAddingMerchant) {
      state.isAddingMerchant = isAddingMerchant
    },

    SET_PRODUCT_INSTRUCTIONS(state, value) {
      state.productInstructions = value
    },

    SET_CURRENT_PRODUCT(state, value) {
      state.currentProduct = value
    },

    SET_CURRENT_PRODUCT_SETTINGS(state, value) {
      state.currentProductSettings = value
    },

    SET_CURRENT_PRODUCT_CURRENCY_SYMBOL(state, value) {
      state.currentProductCurrencySymbol = value
    },

    SET_ALL_MERCHANTS_SHOWING_STATUS(state, isShowingAllMerchants) {
      state.isShowingAllMerchants = isShowingAllMerchants
    },

    SET_CURRENT_MERCHANT(state, curMerchant) {
      state.currentMerchant = curMerchant
    },

    SET_COUNTRIES(state, countries) {
      state.countries = countries
    },

    SET_CURRENT_LOCATION(state, curLocation) {
      state.currentLocation = curLocation
    },

    UPDATE_ADD_MERCHANT_FORM_CHANGED(state, value) {
      state.isAddMerchantFormChanged = value
    },

    SET_LOCATIONS(state, locations) {
      state.locations = locations
    },
    UPDATE_PRODUCT_INSTRUCTION_FORM_CHANGED(state, value) {
      state.isProductInstructionFormChanged = value
    },
    UPDATE_ADD_LOCATION_FORM_CHANGED(state, value) {
      state.isAddLocationFormChanged = value
    },
    SET_LOCATION_UPDATING_STATUS(state, value) {
      state.isUpdatingLocation = value
    },
    SET_TABS_SHOWING(state, value) {
      state.searchTabsShowing = value
    },
    SET_LICENCE_PLATE_PARKINGS_LIST(state, list) {
      state.licencePlateParkingsList = list
    },
    ADD_TO_LICENCE_PLATE_PARKINGS_LIST(state, list) {
      state.licencePlateParkingsList.push(...list)
    },
    SET_SEARCH_BY_LICENCE_PERIOD(state, { startDate, endDate }) {
      state.searchByLicenceStartDate = startDate
      state.searchByLicenceEndDate = endDate
    },
    SET_MORE_LICENCE_PLATES_BTN_STATE(state, btnState) {
      state.showLoadMoreLicencePlatesBtn = btnState
    },
    SET_REPORT_TEMPLATES(state, templatesList) {
      state.reportTemplates = templatesList
    },
    SET_FREQUENCY_TYPES(state, frequencyTypes) {
      state.frequencyTypes = frequencyTypes
    }
  },
  actions: {
    setShowOverlay({ commit }, data) {
      commit('SET_SHOW_OVERLAY', data)
    },
    setSelectedMerchantId({ commit }, data) {
      commit('SET_SELECTED_MERCHANT_ID', data)
    },
    setPageReadyStatus({ commit }, isReady) {
      commit('SET_PAGE_READY_STATUS', isReady)
    },
    setSystemMessage({ commit }, message) {
      commit('SET_SYSTEM_MESSAGE', message)
    },
    setAnonymousView({ commit }, component) {
      commit('SET_ANONYMOUS_VIEW', component)
      // also scroll to the top of the page, for mobile compatibility
      helpers.scrollToSection('app')
    },
    setUserData({ commit }, data) {
      commit('SET_USER_DATA', data)
    },
    setCompanyData({ commit }, data) {
      commit('SET_COMPANY_DATA', data)
    },
    setMerchantsData({ commit }, data) {
      commit('SET_MERCHANTS_DATA', [
        ...new Map(data.map((item) => [item.id, item])).values()
      ])
    },
    async setAllLocationsData({ commit }, data) {
      commit(
        'SET_ALL_LOCATIONS_DATA',
        [...new Map(data.map((item) => [item.id, item])).values()].map(
          (location) => {
            if (location.name === '') {
              location.name = `Location ${location.id}`
            }
            return location
          }
        )
      )
    },
    async setFilteredLocationsData({ commit }, data) {
      commit(
        'SET_FILTERED_LOCATIONS_DATA',
        data
          .filter(
            (value, index, self) =>
              index === self.findIndex((i) => i.id === value.id)
          )
          .map((location) => {
            if (location.name === '') {
              location.name = `Location ${location.id}`
            }
            return location
          })
      )
    },
    clearFilteredLocationsData({ commit }) {
      commit('CLEAR_FILTERED_LOCATIONS_DATA')
    },
    setGroupData({ commit }, data) {
      commit('SET_GROUP_DATA', data)
    },
    setVehicleData({ commit }, data) {
      commit('SET_VEHICLE_DATA', data)
    },
    setEmployeeData({ commit }, data) {
      commit('SET_EMPLOYEE_DATA', data)
    },
    setPricingData({ commit }, data) {
      commit('SET_PRICING_DATA', data)
    },
    setRoleOptions({ commit }, data) {
      commit('SET_ROLE_OPTIONS', data)
    },
    setAccountRoleOptions({ commit }, data) {
      commit('SET_ACCOUNT_ROLE_OPTIONS', data)
    },
    setMerchantAccounts({ commit }, data) {
      commit('SET_MERCHANT_ACCOUNTS', data)
    },
    setPaymentOptions({ commit }, data) {
      commit('SET_PAYMENT_OPTIONS', data)
    },
    setSelectedGroup({ commit }, data) {
      commit('SET_SELECTED_GROUP', data)
    },
    setSignupData({ commit }, data) {
      commit('SET_SIGNUP_DATA', data)
    },
    setLoginIdentifier({ commit }, data) {
      commit('SET_LOGIN_IDENTIFIER', data)
    },
    clearCompanyData({ commit }) {
      commit('CLEAR_COMPANY_DATA')
    },
    setCurrentCompanyId({ commit }, data) {
      commit('SET_CURRENT_COMPANY_ID', data)
    },
    setUserCompanies({ commit }, data) {
      commit('SET_USER_COMPANIES', data)
    },
    setRequestNewDashboardData({ commit }, data) {
      commit('SET_REQUEST_NEW_DASHBOARD_DATA', data)
    },
    setSidebar({ commit }, data) {
      commit('SET_SIDEBAR', data)
    },

    clearMerchantData({ commit }) {
      commit('CLEAR_MERCHANT_DATA')
    },

    setMerchant({ commit }, data) {
      commit('SET_MERCHANT', data)
    },

    setCurrentMerchant({ commit }, data) {
      commit('SET_CURRENT_MERCHANT', data)
    },

    setCountries({ commit }, data) {
      commit('SET_COUNTRIES', data)
    },

    setCurrentLocation({ commit }, data) {
      commit('SET_CURRENT_LOCATION', data)
    },

    setLocationAddingStatus({ commit }, data) {
      commit('SET_LOCATION_ADDING_STATUS', data)
    },

    setMerchantAddingStatus({ commit }, data) {
      commit('SET_MERCHANT_ADDING_STATUS', data)
    },

    setProductInstructions({ commit }, data) {
      commit('SET_PRODUCT_INSTRUCTIONS', data)
    },

    setCurrentProduct({ commit }, data) {
      commit('SET_CURRENT_PRODUCT', data)
    },

    clearCurrentProduct({ commit }) {
      commit('SET_CURRENT_PRODUCT', null)
      commit('SET_CURRENT_PRODUCT_SETTINGS', {})
      commit('SET_CURRENT_PRODUCT_CURRENCY_SYMBOL', '')
    },

    setCurrentProductSettings({ commit }, data) {
      commit('SET_CURRENT_PRODUCT_SETTINGS', data)
      const actualCurrency = CurrencyTypesEnum.find(
        (curr) => curr.id === data?.currency_id
      )
      commit(
        'SET_CURRENT_PRODUCT_CURRENCY_SYMBOL',
        actualCurrency ? actualCurrency.symbol : ''
      )
    },

    setAllMerchantsShowingStatus({ commit }, data) {
      commit('SET_ALL_MERCHANTS_SHOWING_STATUS', data)
    },

    updateFormChanged({ commit }, data) {
      commit('UPDATE_ADD_MERCHANT_FORM_CHANGED', data)
    },

    setMerchantLocations({ commit }, data) {
      commit('SET_LOCATIONS', data)
    },
    setLocationUpdatingStatus({ commit }, data) {
      commit('SET_LOCATION_UPDATING_STATUS', data)
    },
    setSearchTabsShowing({ commit }, data) {
      commit('SET_TABS_SHOWING', data)
    },
    setLicencePlateParkingsList({ commit }, data) {
      commit('SET_LICENCE_PLATE_PARKINGS_LIST', data)
    },
    addToLicencePlateParkingsList({ commit }, data) {
      commit('ADD_TO_LICENCE_PLATE_PARKINGS_LIST', data)
    },
    setSearchByLicencePeriod({ commit }, date) {
      commit('SET_SEARCH_BY_LICENCE_PERIOD', date)
    },
    setMoreLicencePlatesBtnState({ commit }, state) {
      commit('SET_MORE_LICENCE_PLATES_BTN_STATE', state)
    },
    setReportTemplates({ commit }, list) {
      commit('SET_REPORT_TEMPLATES', list)
    },
    setFrequencyTypesTemplates({ commit }, list) {
      commit('SET_FREQUENCY_TYPES', list)
    }
  }
})
