import api from '@/helpers/api'
import i18n from '@/plugins/i18n'
import store from '@/store'
import auth from '@/auth'
import helpers from '@/helpers'
import Vue from 'vue'
import axios from 'axios'
import fileDownload from 'js-file-download'

const DEFAULT_FIELD_VALUES = {
  kick_back_fee_fixed: 0,
  kick_back_fee_percentage: 0,
  vat_percentage: null,
  commission_fixed: 0,
  commission_percentage: 0
}

export default {
  /**
   * Fetch logotypes for downloaded merchants.
   *
   * @returns {Promise<void>}
   */
  async fetchMerchantLogoList(merchantIds = null) {
    const isAdmin = auth.isParkmanAdmin()
    const merchantIdsParams =
      merchantIds ||
      store.getters.merchants.map((merchant) => merchant.id).join(',')
    const apiLogoList = {
      method: 'get',
      service: 'merchant',
      url: 'merchants/logo-list',
      params: { merchantIds: merchantIdsParams },
      isAdmin
    }

    try {
      const { data: logos } = await api.promise(apiLogoList)
      store.getters.merchants.forEach((merchant) => {
        const foundLogo = logos.find((logo) => logo.merchant_id === merchant.id)
        if (foundLogo) {
          // To notify vue about object value changing
          Vue.set(merchant, 'logo', foundLogo.public_url)
        }
      })
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },

  /**
   * Fetch all merchants and save them in vuex
   */
  async fetchAllMerchants() {
    if (store.getters.merchants.length === 0) {
      store.dispatch('setSystemMessage', {
        message: `${i18n.t('loading_all_merchants')}...`,
        type: 'success',
        once: true
      })
    }

    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: 'merchants',
      isAdmin: auth.isParkmanAdmin()
    }

    try {
      const response = await api.promise(apiSettings)
      let merchants = []
      if (auth.isParkmanAdmin()) {
        const pagination = response.data
        merchants = pagination.data
        if (pagination.current_page < pagination.last_page) {
          // Let asynchronously fetch other merchants without blocking user
          for (let i = 2; i <= pagination.last_page; i++) {
            _fetchAllMerchantsForAdmin(i)
          }
        }
      } else {
        merchants = response.data.map((item) => {
          return item.merchant
        })
      }
      merchants = merchants.filter(
        (value, index, self) =>
          index === self.findIndex((i) => i.id === value.id)
      )

      store.dispatch('setMerchantsData', merchants)
      store.dispatch('setSystemMessage', null)

      return merchants
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },

  /**
   * Save product instruction for location
   */
  async saveProductInstruction(locationId, data, editMode = false) {
    await store.dispatch('setShowOverlay', true)

    const requests = []

    const url = `merchants/locations/${locationId}/instructions`

    for (const [index, instruction] of data.instructions.entries()) {
      requests.push(
        api.promise({
          method: 'post',
          service: 'merchant',
          url: instruction.id ? url + `/${instruction.id}` : url,
          isAdmin: auth.isParkmanAdmin(),
          data: {
            product_id: data.product_id,
            step: index + 1,
            instruction_type_id: data.instruction_type_id,
            force_user_to_read: data.force_user_to_read,
            title_translations: JSON.stringify({ en: instruction.title }),
            message_translations: JSON.stringify({ en: instruction.message }),
            image: helpers.isBase64(instruction.image)
              ? instruction.image
              : null,
            delete_image: instruction.delete_image === true,
            contact_phone_number: instruction.contact_phone_number
          }
        })
      )
    }

    try {
      const response = await Promise.all(requests)

      response.forEach((instruction) => {
        const index = store.getters.productInstructions.findIndex(
          (item) => item.id === instruction.data.id
        )
        if (index !== -1) {
          Vue.set(store.getters.productInstructions, index, instruction.data)
        } else {
          store.getters.productInstructions.push(instruction.data)
        }
      })

      if (editMode) {
        store.dispatch('setSystemMessage', {
          message: `${i18n.t('changes_saved')}`,
          type: 'success'
        })
      } else {
        store.dispatch('setSystemMessage', {
          message: `${i18n.t('instructions_were_added')}`,
          type: 'success'
        })
      }

      return response
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    } finally {
      await store.dispatch('setShowOverlay', false)
    }
  },

  async getBase64(url) {
    return axios
      .get(url, {
        responseType: 'arraybuffer'
      })
      .then((response) =>
        Buffer.from(response.data, 'binary').toString('base64')
      )
  },

  /**
   * Delete product instruction
   */
  async deleteProductInstruction(locationId, instructionId, title) {
    store.dispatch('setSystemMessage', {
      message: i18n.t('removing_product_instruction'),
      type: 'success'
    })

    try {
      await api.promise({
        method: 'delete',
        service: 'merchant',
        url: `merchants/locations/${locationId}/instructions/${instructionId}`,
        isAdmin: auth.isParkmanAdmin()
      })

      store.dispatch('setSystemMessage', {
        message: i18n.t('product_instruction_removed', { title }),
        type: 'delete'
      })
    } catch (error) {
      await store.dispatch('setSystemMessage', error)
    }
  },

  /**
   * Fetch product instructions
   */
  async fetchProductInstructions(locationId) {
    store.dispatch('setSystemMessage', {
      message: `${i18n.t('fetching_product_instructions')}...`,
      type: 'success',
      once: true
    })

    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `merchants/locations/${locationId}/instructions`,
      isAdmin: auth.isParkmanAdmin()
    }

    try {
      const { data: response } = await api.promise(apiSettings)

      store.dispatch('setProductInstructions', response.data)
      store.dispatch('setSystemMessage', null)

      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },

  /**
   * Create merchant
   */
  async createMerchant(merchantData) {
    store.dispatch('setSystemMessage', {
      message: `${i18n.t('saving_merchant')}...`,
      type: 'success'
    })

    const apiMerchant = {
      method: 'post',
      service: 'merchant',
      url: 'merchants',
      data: Object.assign({}, merchantData, { logo: null }),
      isAdmin: auth.isParkmanAdmin()
    }

    try {
      const response = await api.promise(apiMerchant)
      const merchant = response.data
      if (merchantData.logo && helpers.isBase64(merchantData.logo)) {
        const apiMerchantLogo = {
          method: 'post',
          service: 'merchant',
          url: `merchants/${merchant.id}/logo`,
          data: { logo: merchantData.logo },
          isAdmin: auth.isParkmanAdmin()
        }
        await api.promise(apiMerchantLogo)
      }
      await store.dispatch('setSystemMessage', null)
      store.dispatch(
        'setMerchantsData',
        store.getters.merchants.concat(merchant)
      )

      return merchant
    } catch (error) {
      await store.dispatch('setSystemMessage', {
        message: error,
        type: 'error'
      })
    }
  },

  /**
   * Update merchant info
   */
  async updateMerchant(merchantData) {
    store.dispatch('setSystemMessage', {
      message: `${i18n.t('saving_merchant')}...`,
      type: 'success'
    })

    const apiMerchant = {
      method: 'put',
      service: 'merchant',
      url: `merchants/${merchantData.id}`,
      data: Object.assign({}, merchantData, { logo: null }),
      isAdmin: auth.isParkmanAdmin()
    }

    const promises = [api.promise(apiMerchant)]

    const apiMerchantLogo = {
      method: 'post',
      service: 'merchant',
      url: `merchants/${merchantData.id}/logo`,
      isAdmin: auth.isParkmanAdmin()
    }

    if (merchantData.logo && helpers.isBase64(merchantData.logo)) {
      apiMerchantLogo.data = { logo: merchantData.logo }
      promises.push(api.promise(apiMerchantLogo))
    } else if (merchantData.logo === null) {
      apiMerchantLogo.method = 'delete'
      promises.push(api.promise(apiMerchantLogo))
    }

    try {
      let [{ data: merchant }, logo] = await Promise.all(promises)
      await store.dispatch('setSystemMessage', null)
      const merchantLogo =
        logo && logo.data ? logo.data.public_url : merchantData.logo
      merchant = Object.assign(merchant, { logo: merchantLogo })
      await store.dispatch('setCurrentMerchant', merchant)

      // To update logo in merchant list
      store.getters.merchants.forEach((item) => {
        if (item.id === merchant.id) {
          Vue.set(item, 'logo', merchantLogo)
        }
      })

      return merchant
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },

  /**
   * Fetch merchant and save it in vuex
   */
  async fetchMerchant(merchantId) {
    store.dispatch('setSystemMessage', {
      message: `${i18n.t('loading_merchant')}...`,
      type: 'success',
      once: true
    })

    const apiMerchant = {
      method: 'get',
      service: 'merchant',
      url: `merchants/${merchantId}`,
      isAdmin: auth.isParkmanAdmin()
    }

    const apiMerchantLogo = {
      method: 'get',
      service: 'merchant',
      url: `merchants/${merchantId}/logo`,
      isAdmin: auth.isParkmanAdmin()
    }

    try {
      let [{ data: merchant }, { data: logo }] = await Promise.all([
        api.promise(apiMerchant),
        api.promise(apiMerchantLogo).catch(() => ({ data: { public_url: '' } }))
      ])

      merchant = Object.assign(merchant, { logo: logo.public_url })

      for (const [field, defaultValue] of Object.entries(
        DEFAULT_FIELD_VALUES
      )) {
        if (merchant[field] === null) {
          merchant[field] = defaultValue
        }
      }

      store.dispatch('setCurrentMerchant', merchant)
      store.dispatch('setSystemMessage', null)

      return merchant
    } catch (error) {
      store.dispatch('setSystemMessage', { ...error, once: true })
    }
  },

  /**
   * Delete merchant
   */
  async deleteMerchant(merchantId) {
    store.dispatch('setSystemMessage', {
      message: `${i18n.t('deleting_merchant')}...`,
      type: 'success'
    })

    const apiMerchant = {
      method: 'delete',
      service: 'merchant',
      url: `merchants/${merchantId}`,
      isAdmin: auth.isParkmanAdmin()
    }

    const apiMerchantLogo = {
      method: 'get',
      service: 'merchant',
      url: `merchants/${merchantId}/logo`,
      isAdmin: auth.isParkmanAdmin()
    }

    try {
      await api.promise(apiMerchantLogo).catch(() => null)
      await api.promise(apiMerchant)

      store.dispatch('setCurrentMerchant', null)
      store.dispatch('setSystemMessage', null)
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },

  /**
   * Fetch countries list
   */
  async fetchCountries() {
    const apiCountries = {
      method: 'get',
      service: 'merchant',
      url: 'merchants/countries',
      isAdmin: auth.isParkmanAdmin()
    }

    try {
      const { data: countries } = await api.promise(apiCountries)

      store.dispatch('setCountries', countries)

      return countries
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },

  /**
   * Fetch all users of one merchant
   */
  async fetchAllUsersForMerchant(merchantId) {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `merchants/${merchantId}/users`,
      isAdmin: auth.isParkmanAdmin()
    }

    try {
      const response = await api.promise(apiSettings)
      const users = response.data
      await store.dispatch('setSystemMessage', null)

      return users
    } catch (error) {
      await store.dispatch('setSystemMessage', error)
    }
  },

  /**
   * Add a merchant user
   */
  async addMerchantUser(merchantId, data) {
    const apiSettings = {
      method: 'post',
      service: 'merchant',
      url: `merchants/${merchantId}/users`,
      data: data,
      isAdmin: auth.isParkmanAdmin()
    }

    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
      return false
    }
  },
  /**
   * Delete a merchant user
   */
  async deleteMerchantUser(merchantId, userId) {
    const apiSettings = {
      method: 'delete',
      service: 'merchant',
      url: `merchants/${merchantId}/users/${userId}`,
      isAdmin: auth.isParkmanAdmin()
    }

    try {
      await api.promise(apiSettings)
      return true
    } catch (error) {
      await store.dispatch('setSystemMessage', error)
      return false
    }
  },

  async getLocationsByMerchant(merchantId) {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `merchants/${merchantId}/locations`,
      isAdmin: auth.isParkmanAdmin(),
      params: { per_page: 1000 }
    }

    try {
      const response = await api.promise(apiSettings)

      return response.data.data.map((location) => {
        if (!location.name) {
          location.name = `Location ${location.id}`
        }

        return location
      })
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  /**
   * Create merchant
   */
  async createLocation(locationData) {
    store.dispatch('setSystemMessage', {
      message: `${i18n.t('saving_location')}...`,
      type: 'success'
    })

    const apiSettings = {
      method: 'post',
      service: 'merchant',
      url: 'merchants/locations',
      data: locationData,
      isAdmin: auth.isParkmanAdmin()
    }

    try {
      const { data: location } = await api.promise(apiSettings)

      store.dispatch('setSystemMessage', null)

      return location
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  /**
   * Delete location
   */
  async deleteLocation(locationId, action) {
    store.dispatch('setSystemMessage', {
      message: `${i18n.t('deleting_location')}...`,
      type: 'success'
    })

    const apiLocation = {
      method: 'post',
      service: 'merchant',
      url: `merchants/locations/${locationId}/delete`,
      data: { delete_action: action },
      isAdmin: auth.isParkmanAdmin()
    }

    try {
      await api.promise(apiLocation)

      store.dispatch('setCurrentLocation', null)
      store.dispatch('setSystemMessage', null)
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  /**
   * Request delete location or deactivate products
   */
  async requestDeleteLocation(locationId, action) {
    store.dispatch('setSystemMessage', {
      message: `${i18n.t('deleting_location')}...`,
      type: 'success'
    })

    const apiLocation = {
      method: 'post',
      service: 'merchant',
      url: `merchants/locations/${locationId}/request_delete`,
      data: { delete_action: action },
      isAdmin: auth.isParkmanAdmin()
    }

    try {
      await api.promise(apiLocation)

      store.dispatch('setCurrentLocation', null)
      store.dispatch('setSystemMessage', null)
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  /**
   * Update location
   */
  async updateLocation(locationId, data) {
    store.dispatch('setSystemMessage', {
      message: `${i18n.t('updating_location')}...`,
      type: 'success'
    })

    const apiLocation = {
      method: 'put',
      service: 'merchant',
      url: `merchants/locations/${locationId}`,
      data: data,
      isAdmin: auth.isParkmanAdmin()
    }

    try {
      const { data: location } = await api.promise(apiLocation)
      store.dispatch('setSystemMessage', null)
      return location
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  /**
   * Reject deleting location or deactivate products
   */
  async rejectDeleteLocation(locationId) {
    store.dispatch('setSystemMessage', {
      message: `${i18n.t('updating_location')}...`,
      type: 'success'
    })

    const apiLocation = {
      method: 'post',
      service: 'merchant',
      url: `merchants/locations/${locationId}/deny_deletion`,
      isAdmin: auth.isParkmanAdmin()
    }

    try {
      const { data: location } = await api.promise(apiLocation)
      store.dispatch('setSystemMessage', null)
      return location
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  /**
   * Deactivate location's product
   */
  async deleteLocationProduct(locationId, productId) {
    store.dispatch('setSystemMessage', {
      message: `${i18n.t('deleting_product')}...`,
      type: 'success'
    })

    const apiLocation = {
      method: 'delete',
      service: 'merchant',
      url: `merchants/locations/${locationId}/products/${productId}`,
      isAdmin: true
    }

    try {
      await api.promise(apiLocation)
      store.dispatch('setSystemMessage', null)
    } catch (error) {
      store.dispatch('setSystemMessage', error)
      throw error
    }
  },
  /**
   * Update product advanced settings
   */
  async updateProductAdvancedSettings(locationId, productId, data) {
    store.dispatch('setSystemMessage', {
      message: `${i18n.t('updating_product')}...`,
      type: 'success'
    })

    const apiSettings = {
      method: 'put',
      service: 'merchant',
      url: `merchants/locations/${locationId}/advanced-settings/${productId}`,
      data: data,
      isAdmin: auth.isParkmanAdmin()
    }

    try {
      const { data: location } = await api.promise(apiSettings)
      store.dispatch('setSystemMessage', null)
      return location
    } catch (error) {
      store.dispatch('setSystemMessage', error)
      throw error
    }
  },
  async getProductAdvancedSettings(locationId, productId) {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `merchants/locations/${locationId}/advanced-settings/${productId}`,
      isAdmin: auth.isParkmanAdmin()
    }

    try {
      const response = await api.promise(apiSettings)

      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async getHubsList(locationId) {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `merchants/locations/${locationId}/hub`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      // FIXIT when ep returns Array
      return [response.data]
    } catch (error) {
      // FIXIT delete when ep returns not 404 error
      return []
    }
  },
  async getMerchantHubsList(merchantId) {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `merchants/${merchantId}/hub-locations`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      // FIXIT when ep will be ready
      return response.data
    } catch (error) {
      return []
    }
  },
  async getWardensList(url) {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: url
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data.wardens
    } catch (error) {
      await store.dispatch('setSystemMessage', error)
      return []
    }
  },
  async getAllAccountLocations() {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: 'merchants/account-locations',
      params: {},
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      const pagination = response.data
      await store.dispatch(
        'setAllLocationsData',
        store.getters.allLocations.concat(pagination.data)
      )
      if (pagination.current_page < pagination.last_page) {
        const pages = []
        for (let i = 2; i <= pagination.last_page; i++) {
          pages.push(i)
        }
        await Promise.all(pages.map((page) => _fetchAllAccountLocations(page)))
      } else {
        store.dispatch('setAllLocationsData', pagination.data)
      }

      await store.dispatch('setSystemMessage', null)
    } catch (error) {
      await store.dispatch('setSystemMessage', error)
    }
  },
  async getNearByLocations(point) {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: 'locations/nearby',
      params: { point },
      isAdmin: false
    }
    try {
      const response = await api.promise(apiSettings)
      await store.dispatch(
        'setAllLocationsData',
        store.getters.allLocations.concat(response.data)
      )

      return response.data.map((location) => {
        if (location.name === '') {
          location.name = `Location ${location.id}`
        }
        return location
      })
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async createPricingSchedule(merchantId, data) {
    const apiSettings = {
      method: 'post',
      service: 'merchant',
      url: `pricing/merchants/${merchantId}/schedules`,
      data: data,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async updatePricingSchedule(merchantId, scheduleId, data) {
    const apiSettings = {
      method: 'put',
      service: 'merchant',
      url: `pricing/merchants/${merchantId}/schedules/${scheduleId}`,
      data: data,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async deletePricingSchedule(merchantId, scheduleId) {
    const apiSettings = {
      method: 'delete',
      service: 'merchant',
      url: `pricing/merchants/${merchantId}/schedules/${scheduleId}`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async getPricingSchedule(merchantId, locationId) {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `pricing/merchants/${merchantId}/schedules`,
      params: { location_id: locationId, product_id: 1 },
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async getPricingScheduleDetails(merchantId, scheduleId) {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `pricing/merchants/${merchantId}/schedules/${scheduleId}`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async getPricingStatus() {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: 'pricing/schedules/list_statusess',
      isAdmin: false
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async submitPricingScheduleForReview(merchantId, scheduleId) {
    const apiSettings = {
      method: 'post',
      service: 'merchant',
      url: `pricing/merchants/${merchantId}/schedules/${scheduleId}/submit_for_review`,
      isAdmin: true
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      await store.dispatch('setSystemMessage', error)
      throw error
    }
  },
  async changePricingScheduleToDraft(merchantId, scheduleId) {
    const apiSettings = {
      method: 'post',
      service: 'merchant',
      url: `pricing/merchants/${merchantId}/schedules/${scheduleId}/change_to_draft`,
      isAdmin: true
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      await store.dispatch('setSystemMessage', error)
      throw error
    }
  },
  async approvePricingScheduleReview(merchantId, scheduleId) {
    const apiSettings = {
      method: 'post',
      service: 'merchant',
      url: `pricing/merchants/${merchantId}/schedules/${scheduleId}/approve_review`,
      isAdmin: true
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      await store.dispatch('setSystemMessage', error)
      throw error
    }
  },
  async createWeekdayPrices(merchantId, scheduleId, data) {
    const apiSettings = {
      method: 'post',
      service: 'merchant',
      url: `pricing/merchants/${merchantId}/schedules/${scheduleId}/weekday_paid_times`,
      data: data,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      await store.dispatch('setSystemMessage', error)
    }
  },
  async deleteWeekdayPrices(merchantId, scheduleId, data) {
    const apiSettings = {
      method: 'delete',
      service: 'merchant',
      url: `pricing/merchants/${merchantId}/schedules/${scheduleId}/weekday_paid_times`,
      data: data,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      await store.dispatch('setSystemMessage', error)
      throw error
    }
  },
  async createSequencePrices(merchantId, scheduleId, data) {
    const apiSettings = {
      method: 'post',
      service: 'merchant',
      url: `pricing/merchants/${merchantId}/schedules/${scheduleId}/sequence_price_steps`,
      data: data,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      await store.dispatch('setSystemMessage', error)
      throw error
    }
  },
  async updateSequencePrices(merchantId, scheduleId, sequenceId, data) {
    const apiSettings = {
      method: 'put',
      service: 'merchant',
      url: `pricing/merchants/${merchantId}/schedules/${scheduleId}/sequence_price_steps/${sequenceId}`,
      data: data,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      await store.dispatch('setSystemMessage', error)
      throw error
    }
  },
  async deleteSequencePrices(merchantId, scheduleId, sequenceId) {
    const apiSettings = {
      method: 'delete',
      service: 'merchant',
      url: `pricing/merchants/${merchantId}/schedules/${scheduleId}/sequence_price_steps/${sequenceId}`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      await store.dispatch('setSystemMessage', error)
      throw error
    }
  },
  async createPeriodicPrices(merchantId, scheduleId, data) {
    const apiSettings = {
      method: 'post',
      service: 'merchant',
      url: `pricing/merchants/${merchantId}/schedules/${scheduleId}/periodic_price_items`,
      data: data,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      await store.dispatch('setSystemMessage', error)
      throw error
    }
  },
  async updatePeriodicPrices(merchantId, scheduleId, periodicId, data) {
    const apiSettings = {
      method: 'put',
      service: 'merchant',
      url: `pricing/merchants/${merchantId}/schedules/${scheduleId}/periodic_price_items/${periodicId}`,
      data: data,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      await store.dispatch('setSystemMessage', error)
      throw error
    }
  },
  async deletePeriodicPrices(merchantId, scheduleId, periodicId) {
    const apiSettings = {
      method: 'delete',
      service: 'merchant',
      url: `pricing/merchants/${merchantId}/schedules/${scheduleId}/periodic_price_items/${periodicId}`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      await store.dispatch('setSystemMessage', error)
      throw error
    }
  },
  async fetchTemplatesList() {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: 'reports/templates-list',
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async fetchFrequencyTypesList() {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: 'reports/frequency-types-list',
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async fetchReportSettings(merchantId) {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `merchants/${merchantId}/report-settings`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      if (
        error.message !== 'Report settings not found.' &&
        error.message !== 'Not found.'
      ) {
        store.dispatch('setSystemMessage', error)
      }
    }
  },
  async setReportSettings(merchantId, data) {
    const apiSettings = {
      method: 'post',
      service: 'merchant',
      url: `merchants/${merchantId}/report-settings`,
      data: data,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      store.dispatch('setSystemMessage', {
        message: i18n.t('updated_confirm_message', {
          object: i18n.t('report_settings')
        }),
        type: 'success'
      })
      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async getReceivers(reportSettingId) {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `reports/${reportSettingId}/receivers`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async addReceiver(reportSettingId, data) {
    const apiSettings = {
      method: 'post',
      service: 'merchant',
      url: `reports/${reportSettingId}/receivers`,
      data: data,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async deleteReceiver(recipient) {
    const apiSettings = {
      method: 'delete',
      service: 'merchant',
      url: `reports/receivers/${recipient.id}`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      await api.promise(apiSettings)
      store.dispatch('setSystemMessage', {
        message: i18n.t('removed_confirm_message', { object: recipient.email }),
        type: 'delete'
      })
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async fetchHistoryPayouts(merchantId, startTime, endTime) {
    var params = {}
    if (startTime !== null && endTime !== null) {
      params = {start_time: startTime, end_time: endTime}
    }
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `reports/${merchantId}/list`,
      isAdmin: auth.isParkmanAdmin(),
      params: params
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      if (
        error.message !== 'Report settings not found.' &&
        error.message !== 'Not found.'
      ) {
        store.dispatch('setSystemMessage', error)
      }
    }
  },
  /**
 * Download selected report
 */
  async download(merchantId, reportId) {

    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `reports/${merchantId}/${reportId}/download`,
      isAdmin: auth.isParkmanAdmin(),
      responseType: 'blob'
    }

    try {
      const response = await api.promise(apiSettings)
      fileDownload(response, `reports_${reportId}.xlsx`)
      
    } catch (error) {
      store.dispatch('setSystemMessage', i18n.t('transactions_export_failed'))
    }
  },
  async fetchAllCountryPayouts(startTime, endTime) {
    
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `reports/country/list`,
      isAdmin: true,
      params: { start_timestamp: startTime, end_timestamp: endTime }
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      if (
        error.message !== 'Report settings not found.' &&
        error.message !== 'Not found.'
      ) {
        store.dispatch('setSystemMessage', error)
      }
    }
  },
  async fetchMerchantDiscounts(merchantId) {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `discounts/merchant/${merchantId}`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async fetchMerchantHolidays(merchantId, listId) {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `discounts/holiday-dates/merchant/${merchantId}/${listId}`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async findEcoCar(data) {
    const apiSettings = {
      method: 'post',
      service: 'merchant',
      url: `ecocars/find`,
      data: data,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      return null
    }
  },
  async addEcoCar(data) {
    const apiSettings = {
      method: 'post',
      service: 'merchant',
      url: `ecocars`,
      data: data,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      await api.promise(apiSettings)
      return true
    } catch (error) {
      return false
    }
  },
  async removeEcoCar(data) {
    const apiSettings = {
      method: 'delete',
      service: 'merchant',
      url: `ecocars/remove`,
      data: data,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      await api.promise(apiSettings)
      return true
    } catch (error) {
      return false
    }
  },
  async fetchDiscountedLocations(merchantId, listId) {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `discounts/merchant/${merchantId}/locations/${listId}`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async fetchLocationProductsDiscounts(locationId, productId) {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `discounts/location-products/${locationId}/${productId}`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async fetchMerchantLocationWardens(merchantId, locationId) {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `merchants/${merchantId}/locations/${locationId}/warden`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async fetchWardenDetails(wardenId) {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `wardens/${wardenId}/details`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async fetchWardenList() {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `wardens/list`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async createLocationAndWardenConnection(locationId, wardenId) {
    const apiSettings = {
      method: 'post',
      service: 'merchant',
      url: `merchants/locations/${locationId}/warden/${wardenId}`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      await api.promise(apiSettings)
      return true
    } catch (error) {
      return false
    }
  },
  async deleteLocationAndWardenConnection(locationId, wardenId) {
    const apiSettings = {
      method: 'delete',
      service: 'merchant',
      url: `merchants/locations/${locationId}/warden/${wardenId}`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      await api.promise(apiSettings)
      return true
    } catch (error) {
      return false
    }
  },
  async fetchMerchantLocationHubs(merchantId, locationId) {
    const apiSettings = {
      method: 'get',
      service: 'merchant',
      url: `merchants/locations/${locationId}/hub`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response.data
    } catch (error) {
      if (error.message === 'Hub not found') {
        return null
      } else {
        store.dispatch('setSystemMessage', error)
      }
    }
  },
  async fetchMerchantHubs(merchantId) {
    const apiSettings = {
      method: 'get',
      service: 'parking',
      url: `merchants/${merchantId}/hubs`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async addHubToLocation(data) {
    const apiSettings = {
      method: 'post',
      service: 'parking',
      url: `location/hub`,
      data: data,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async deleteHubFromLocation(locationId) {
    const apiSettings = {
      method: 'delete',
      service: 'parking',
      url: `location/${locationId}/hub`,
      isAdmin: auth.isParkmanAdmin()
    }
    try {
      const response = await api.promise(apiSettings)
      return response
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  }
}

function _fetchAllMerchantsForAdmin(page = 1) {
  const apiSettings = {
    method: 'get',
    service: 'merchant',
    url: `merchants?page=${page}`,
    isAdmin: true,
    disableLoader: true
  }

  try {
    api.promise(apiSettings).then((response) => {
      const pagination = response.data
      store.dispatch(
        'setMerchantsData',
        store.getters.merchants.concat(pagination.data)
      )
    })
  } catch (error) {
    store.dispatch('setSystemMessage', error)
  }
}

async function _fetchAllAccountLocations(page = 1) {
  const apiSettings = {
    method: 'get',
    service: 'merchant',
    url: `merchants/account-locations?page=${page}`,
    isAdmin: true,
    disableLoader: true
  }

  try {
    return api
      .promise(apiSettings)
      .then((response) => response.data)
      .then((pagination) => {
        return store.dispatch(
          'setAllLocationsData',
          store.getters.allLocations.concat(pagination.data)
        )
      })
  } catch (error) {
    return store.dispatch('setSystemMessage', error)
  }
}
