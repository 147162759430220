import Vue from 'vue'
import axios from 'axios'
import decode from 'jwt-decode'
import moment from 'moment'

import auth from '@/auth'
import i18n from '@/plugins/i18n'
import store from '@/store'

import companyHelpers from '@/helpers/company'
import userHelpers from '@/helpers/user'

export default {
  /**
   * Fetch from storage
   * @param {String} key
   */
  fetchStorage(key) {
    const result = localStorage.getItem(key)

    try {
      return JSON.parse(result)
    } catch {
      return result
    }
  },
  /**
   * Save key value pair to storage
   * Used on modern browsers
   * @param {String} key
   * @param {String} value
   */
  saveStorage(key, value) {
    localStorage.setItem(
      key,
      value instanceof Object ? JSON.stringify(value) : value
    )
  },
  /**
   * Clear key value pair from storage
   * @param {String} key
   */
  clearStorage(key) {
    localStorage.removeItem(key)
  },
  /**
   * Get data from pcpr-token stored in local storage
   */
  getTokenInfo() {
    if (!this.fetchStorage('pb-token')) {
      return null
    }

    try {
      return decode(this.fetchStorage('pb-token'))
    } catch (e) {
      auth.logout()
      return null
    }
  },
  /**
   * Smoothly scroll to section with specified ID
   */
  scrollToSection(sectionId, offset) {
    if (offset === undefined) {
      offset = 80 // top of target section minus navigation bar height & hero bottom margin
    }

    const targetEl = document.getElementById(sectionId)

    if (!targetEl) {
      return
    }

    window.scrollBy({
      top: targetEl.getBoundingClientRect().top - offset,
      behavior: 'smooth'
    })
  },
  /**
   * Check if value different than blank, null or undefined
   */
  isValueFilled(value) {
    if (value === '') {
      return false
    }
    if (value === null) {
      return false
    }
    if (value === undefined) {
      return false
    }
    return true
  },
  /**
   * Check if all fields are filled
   * @param {Object} formData
   */
  isAllFieldsFilled(formData) {
    return Object.values(formData).every(this.isValueFilled)
  },
  /**
   * Backend return error messages as array.
   * Transform them into string, separate each message by <br>
   * @param {*} messages
   */
  parseErrorMessage(messages) {
    // check exception
    if (!Array.isArray(messages)) {
      return messages
    }

    return messages.reduce((finalMsg, msgCode) => {
      return finalMsg + i18n.t(msgCode) + '<br>'
    }, '')
  },
  /**
   * Check if user has completed setting up the company
   * @return true if company info filled (at least name filled)
   */
  hasCompletedSetup(userData) {
    if (!userData) {
      return false
    }

    return userData.companies !== null
  },
  /**
   * Send tracking event to Google Analytics
   */
  sendTrackingEvent(category, action) {
    // send event to google analytics
    if (Vue.prototype.$gtag) {
      Vue.prototype.$gtag.event(action, {
        event_category: category
      })
    }
  },
  /**
   * trim special character from the input string
   * @param {String} value
   */
  keepAlphanumeric(value) {
    return value ? String(value).replace(/\W/g, '') : ''
  },

  /**
   * Return visitor country based on location
   */
  getVisitorCountry() {
    let country = process.env.VUE_APP_DEFAULT_COUNTRY
    return new Promise((resolve, reject) => {
      axios
        .get('https://www.cloudflare.com/cdn-cgi/trace')
        .then((response) => {
          if (response.data) {
            const locationInfo = response.data
              .split('\n')
              .find((item) => item.startsWith('loc='))

            if (locationInfo) {
              country = locationInfo.substr(4).toLowerCase()
            }
          }

          resolve(country)
        })
        .catch(() => {
          // return default country in case autodetection fails
          resolve(country)
        })
    })
  },

  getLanguageByCountry(countryCode) {
    switch (countryCode) {
      case 'dk':
      case 'da':
        return 'da'
      case 'it':
      case 'fi':
      case 'lt':
        return countryCode
      case 'se':
        return 'sv'
      default:
        return 'en'
    }
  },

  getCountryByLanguage(languageCode) {
    switch (languageCode) {
      case 'dk':
      case 'da':
        return 'dk'
      case 'it':
      case 'fi':
      case 'lt':
        return languageCode
      case 'sv':
        return 'se'
      default:
        return 'us'
    }
  },

  /**
   * Normally the company object need to be stored to be used across pages.
   * In case page first load, fetch the following info
   * - Logged in user
   * - Company
   * - Groups
   */
  async fetchDashboardData() {
    // first, old stored info
    store.dispatch('clearCompanyData')

    try {
      // fetch user info and company id
      const user = await userHelpers.fetchUserInfo()
      const companyId = this.getCurrentCompanyIdInStorage()

      if (!user || !companyId) {
        // prevent error showing when session timeout
        auth.logout()
        return
      }

      // also fetch detailed company info
      await companyHelpers.fetchCompanyInfo(companyId)

      // set the group list from the company info
      const groups = await companyHelpers.fetchCompanyGroups(companyId)
      if (groups) {
        const defaultGroupId = companyHelpers.getCompanyDefaultGroupId(groups)
        store.dispatch('setSelectedGroup', defaultGroupId)
      }

      // fetch all companies
      const companies = await userHelpers.fetchAllUserCompanies(user.id)
      if (companies) {
        store.dispatch('setUserCompanies', companies)
      }
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  async fetchCompanyManagementData() {
    try {
      // fetch user info
      const user = await userHelpers.fetchUserInfo()

      if (!user) {
        // prevent error showing when session timeout
        auth.logout()
        return
      }

      const companies = await userHelpers.fetchAllUserCompanies(user.id)
      if (!companies || companies.length < 1) {
        auth.logout()
        return
      }

      store.dispatch('setUserCompanies', companies)
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  /**
   * Check if country is supported
   * @param {String} countryCode
   */
  isCountrySupported(countryCode) {
    if (!countryCode) {
      return false
    }

    const supportedCountries = store.getters.countries.map((i) =>
      i.code.toLowerCase()
    )

    return supportedCountries.indexOf(countryCode.toString().toLowerCase()) > -1
  },
  /**
   * Thousand-separator for numbers. Passing 12345 will return 12 345
   * @param {String} number
   * @param {String} separator default is 'space'
   */
  thousandSeparate(number, separator = ' ') {
    if (!number) {
      return 0
    }
    var parts = String(number).split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator)
    return parts.join('.')
  },
  /**
   * Display money value, show currency symbol before value for $, € or £
   * @param {*} amount
   * @param {*} currency
   * @param {Integer} decimalDigits number of decimal digits after value. if not set, use the original number in "amount" param
   */
  displayMoney(amount, currency, decimalDigits) {
    if (isNaN(amount)) {
      return '-'
    }

    const prefixCurrencies = ['$']

    if (decimalDigits) {
      // format amount based on number of decimal digits
      amount = amount.toFixed(decimalDigits)
    }

    // add thousand separator for large amount
    amount = this.thousandSeparate(amount)

    return prefixCurrencies.indexOf(currency) === -1
      ? `${amount} ${currency}`
      : `${currency}${amount}`
  },
  /**
   * Check from env. variable
   * @returns {Boolean} true if current time is x day before maintenance
   */
  isShowingIncomingMaintenanceNotice() {
    const maintenanceStart = moment(process.env.VUE_APP_MAINTENANCE_START)
    const maintenanceNoticeStart = moment(
      process.env.VUE_APP_MAINTENANCE_START
    ).subtract(process.env.VUE_APP_MAINTENANCE_PRIOR_NOTICE_DAYS, 'd')

    return moment().isBetween(maintenanceNoticeStart, maintenanceStart)
  },
  /**
   * Check from env. variable
   * @returns {Boolean} true if current time is between maintenance start / end
   */
  isUnderMaintenance() {
    const maintenanceStart = moment(process.env.VUE_APP_MAINTENANCE_START)
    const maintenanceEnd = moment(process.env.VUE_APP_MAINTENANCE_END)

    return moment().isBetween(maintenanceStart, maintenanceEnd)
  },
  /**
   * Get current company id
   */
  getCurrentCompanyIdInStorage() {
    return this.fetchStorage('current-company-id')
  },
  saveCurrentCompanyId(id) {
    this.saveStorage('current-company-id', id)
  },
  clearCurrentCompanyId() {
    this.clearStorage('current-company-id')
  },
  /**
   * the user has at least one company with an enterprise admin role
   * @returns {Boolean} true if has at least one role available
   */
  hasCompanyWithEnterpriseAdminRole() {
    const companies = store.state.userCompanies
    return companies
      ? companyHelpers.hasCompanyWithEnterpriseAdminRole(companies)
      : false
  },
  getCurrentCompanyName() {
    // save current company id to store
    store.dispatch('setCurrentCompanyId', this.getCurrentCompanyIdInStorage())
    return store.getters.currentCompanyName
  },
  /**
   * Check if two merchants ID are the same by comparing them in String format
   * @param {*} idA
   * @param {*} idB
   */
  areSameId(idA, idB) {
    if (!idA || !idB) {
      return false
    }

    return String(idA) === String(idB)
  },
  /**
   * Check if two zones ID are the same by comparing them in String format
   * @param {*} zoneIdA
   * @param {*} zoneIdB
   */
  areSameZone(zoneIdA, zoneIdB) {
    if (!zoneIdA || !zoneIdB) {
      return false
    }

    return String(zoneIdA) === String(zoneIdB)
  },
  getPathsFromMvcPaths(mvcPaths) {
    const paths = []
    for (let i = 0; i < mvcPaths.getLength(); i++) {
      const path = []
      for (let j = 0; j < mvcPaths.getAt(i).getLength(); j++) {
        const point = mvcPaths.getAt(i).getAt(j)
        path.push({ lat: point.lat(), lng: point.lng() })
      }
      paths.push(path)
    }
    if (paths.length > 0) {
      return paths[0]
    }
    return null
  },
  getPathsFromMvcArray(mvcArray) {
    const results = []

    for (var i = 0; i < mvcArray.getLength(); i++) {
      const lng = mvcArray.getAt(i)
      results.push({ lat: lng.lat(), lng: lng.lng() })
    }
    const latlng = mvcArray.getAt(0)
    results.push({ lat: latlng.lat(), lng: latlng.lng() })

    return results
  },
  /**
   * Select text in value input box on user focus
   */
  selectAllText(event) {
    event.target.setSelectionRange(0, event.target.value.length)
  },

  /**
   * Checks whether provided string is base 64
   *
   * @param value
   * @returns {boolean}
   */
  isBase64(value) {
    if (typeof value !== 'string' || value === '' || value.trim() === '') {
      return false
    }
    value = value.split(',').pop()
    try {
      return btoa(atob(value)) === value
    } catch (err) {
      return false
    }
  },

  /**
   * Check whether the value is number
   *
   * @param value
   * @return {boolean}
   */
  isNumber(value) {
    return typeof value === 'number' && isFinite(value)
  },

  /**
   * Check whether the value is string
   *
   * @param value
   * @return {boolean}
   */
  isString(value) {
    return typeof value === 'string' || value instanceof String
  }
}
