var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content"},[_c('v-row',{staticClass:"row-item d-flex justify-space-between sticky-top"},[_c('v-btn',{staticClass:"button--top text-capitalize button custom-button",attrs:{"text":""},on:{"click":_vm.openMerchant}},[_c('img',{staticClass:"logo",attrs:{"src":_vm.currentLogo}}),_c('span',{staticClass:"truncate-text"},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.sidebarName,15))+" ")])]),(_vm.merchantAmount > 1)?_c('v-btn',{staticClass:"button custom-button",style:(_vm.arrowButtonStyle),attrs:{"icon":""},on:{"click":_vm.switchToChooseMerchant,"mouseover":function($event){_vm.isHoveringArrow = true},"mouseout":function($event){_vm.isHoveringArrow = false}}},[_c('v-icon',{style:(_vm.arrowIconStyle)},[_vm._v(" mdi-chevron-down ")])],1):_vm._e()],1),_c('v-row',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
        handler: _vm.clickOutsideSearch,
        include: _vm.include
      }),expression:"{\n        handler: clickOutsideSearch,\n        include: include\n      }"}],staticClass:"search-content sticky-top"},[_c('sidebar-search',{attrs:{"searchActive":_vm.searchActive},on:{"enableSearchActive":function($event){_vm.searchActive = true},"searchUpdated":function($event){_vm.search = $event},"searchTypeUpdated":function($event){_vm.searchType = $event},"filterLocationsList":_vm.filterLocationsList,"updatePinPoint":_vm.updatePinPoint}}),_c('v-row',{staticClass:"locations-list"},[_c('v-list',{staticClass:"list",attrs:{"flat":""}},[_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.auth.isParkmanAdmin()),expression:"auth.isParkmanAdmin()"}],staticClass:"item add-location",class:{ active: _vm.isAddingLocation }},[_c('v-list-item-content',[_c('div',[_c('v-list-item-title',{staticClass:"text"},[_c('a',{staticClass:"link",on:{"click":_vm.addLocation}},[_c('img',{staticClass:"logo logo-add",attrs:{"src":require("@/assets/add-button.svg")}}),_vm._v(" "+_vm._s(_vm.$t('link.adding_location'))+" ")])])],1)])],1)],1)],1),_c('v-row',{staticClass:"locations-list"},[_c('v-list',{staticClass:"list scrollable",attrs:{"flat":""}},_vm._l((_vm.locationsList),function(location){return _c('v-list-item',{key:location.id,staticClass:"list-item location-list-item",attrs:{"data-id":location.id}},[_c('v-list-item-content',[_c('div',{staticClass:"item list-item-content--location",class:{
                  active:
                    _vm.helpers.areSameId(location.id, _vm.activeLocationId) &&
                    _vm.selectedProduct === null &&
                    _vm.isAddingLocation === false
                },on:{"click":function($event){return _vm.openLocation(location)}}},[_c('v-list-item-title',{staticClass:"text flex-container px-8"},[_c('div',{staticClass:"link d-flex align-center"},[_c('div',{staticClass:"search-content-item-icon"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/surface.png")}})]),_c('div',{staticClass:"search-content-item-text d-flex flex-column justify-start"},[_c('span',{staticClass:"search-content-item-text-title"},[_vm._v(" "+_vm._s(location.name)+" ")]),_c('span',{staticClass:"search-content-item-text-subtitle"},[_vm._v(" Location ID: "+_vm._s(location.id)+" ")])])]),(
                      _vm.locationExpired(location) || _vm.locationInactive(location)
                    )?_c('div',{staticClass:"d-flex justify-end",staticStyle:{"padding-left":"12px","width":"100%"}},[(_vm.locationNeedsAttention(location))?_c('span',{staticClass:"location-expired location-expired--withYellowDot"},[_vm._v("Inactive ")]):_c('span',{staticClass:"location-expired"},[_vm._v("Inactive ")])]):_vm._e(),(_vm.locationNeedsAttention(location))?_c('div',[_c('span',{staticClass:"yellow-dot"})]):_vm._e()])],1),(_vm.helpers.areSameId(location.id, _vm.activeLocationId))?_c('div',{staticClass:"products-section"},[_c('v-list-item-group',_vm._l((_vm.products),function(product){return _c('v-list-item',{key:product.id,staticClass:"item product-item",class:{ active: _vm.selectedProduct === product.id },on:{"click":function($event){return _vm.selectProduct(product, location)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text"},[_c('a',{staticClass:"link"},[_c('span',{staticClass:"product-item-text-title d-block"},[_vm._v(" "+_vm._s(product.name)+" ")])])])],1)],1)}),1)],1):_vm._e()])],1)}),1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }