const CurrencyTypesEnum = Object.freeze([
  {
    id: 1,
    name: 'EUR',
    symbol: '€'
  },
  {
    id: 2,
    name: 'USD',
    symbol: '$'
  },
  {
    id: 3,
    name: 'SEK',
    symbol: 'kr'
  },
  {
    id: 4,
    name: 'DKK',
    symbol: 'kr'
  }
])

export default CurrencyTypesEnum
