import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Sidebar from '@/components/Sidebar/Sidebar'
import Topbar from '@/components/TopNav/TopBar'

import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'

import { MAP_SETTINGS } from '@/helpers/map'
import VueGtag from 'vue-gtag'
import * as VueGoogleMaps from 'vue2-google-maps'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import helpers from '@/helpers'
import ToggleButton from 'vue-js-toggle-button'
Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GA_TID,
    params: {
      send_page_view: true
    }
  }
})

Vue.use(VueGoogleMaps, {
  load: {
    key: MAP_SETTINGS.apiKey,
    libraries: 'drawing,geometry,places,geocoding'
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
  }
})

Vue.use({
  install() {
    Vue.prototype.$helpers = helpers
  }
})

// Global components
Vue.component('sidebar', Sidebar)
Vue.config.productionTip = false

// Global components
Vue.component('topbar', Topbar)

Vue.use(ElementUI)
Vue.use(ToggleButton)
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app')

/** Vue Filters Start */
Vue.filter('truncate', (text, length) => {
  if (text.length > length) {
    return text.split('').slice(0, length).join('') + '...'
  } else {
    return text
  }
})
/** Vue Filters End */
