<template>
  <div>
    <div class="content">
      <v-row class="row-item d-flex justify-space-between sticky-top">
        <v-btn text class="button--top text-capitalize button custom-button">
          <img src="@/assets/landing_logo@2x.png" class="all-merchants-logo" />
          <span class="all-merchants-title">
            {{ $t('title.all_merchants') }}
          </span>
        </v-btn>
        <v-btn
          icon
          class="button"
          @click="switchToChooseMerchant"
          @mouseover="isHoveringArrow = true"
          @mouseout="isHoveringArrow = false"
          :style="arrowButtonStyle"
        >
          <v-icon color="#B9B9B9"> mdi-chevron-down </v-icon>
        </v-btn>
      </v-row>
      <v-row
        class="search-content sticky-top"
        v-click-outside="{
          handler: clickOutsideSearch,
          include: include
        }"
      >
        <sidebar-search
          :locations="locations"
          :searchActive="searchActive"
          @enableSearchActive="searchActive = true"
          @searchUpdated="search = $event"
          @searchTypeUpdated="searchType = $event"
          @filterLocationsList="filterLocationsList"
          @updatePinPoint="updatePinPoint"
        />
        <v-row class="locations-list scrollable">
          <v-list flat class="list">
            <v-list-item
              class="item px-13"
              v-for="location in locationsList"
              @click="switchToMerchant(location)"
              :data-id="location.id"
              :key="location.id"
            >
              <v-list-item-content>
                <div class="link d-flex align-center">
                  <div class="search-content-item-icon">
                    <img class="logo" src="@/assets/surface.png" />
                  </div>
                  <div
                    class="search-content-item-text d-flex flex-column justify-start"
                  >
                    <span class="search-content-item-text-title">
                      {{ location.name }}
                    </span>
                    <span class="search-content-item-text-subtitle">
                      Location ID: {{ location.id }}
                    </span>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-row>
      </v-row>
    </div>
  </div>
</template>

<script>
import SidebarSearch from '@/components/Sidebar/Merchant/SidebarSearch'
import helpers from '@/helpers'
import api from '@/helpers/api'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import eventBus from '@/helpers/eventBus'

export default {
  name: 'AllMerchantsSidebar',
  components: { SidebarSearch },
  props: {
    locations: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      name: '',
      search: '',
      helpers: helpers,
      activeLocationId: '1',
      isHoveringArrow: false,
      searchActive: false,
      searchType: null,
      filteredLocations: null,
      selectedPoint: null,
      nextLicensePlateId: null,
      prevLicenseSearch: '',
      prevLicenseStart: '',
      prevLicenseEnd: ''
    }
  },
  mounted() {
    eventBus.$on('loadLicencePlates', () => {
      this.searchByLicensePlate()
    })
    eventBus.$on('reloadLicencePlates', () => {
      this.clearData()
      this.searchByLicensePlate()
    })
    this.setSearchTabsShowing(true)
  },
  beforeDestroy() {
    this.clearData()
    eventBus.$off('loadLicencePlates')
    eventBus.$off('reloadLicencePlates')
    this.prevLicenseSearch = ''
    this.prevLicenseStart = ''
    this.prevLicenseEnd = ''
  },
  computed: {
    ...mapGetters([
      'showSearchTabs',
      'searchedByLicencePlateList',
      'searchByLicenceStartDate',
      'searchByLicenceEndDate'
    ]),
    arrowButtonStyle() {
      return {
        'background-color': this.isHoveringArrow
          ? '#222222 !important'
          : 'transparent',
        width: '32px',
        height: '32px'
      }
    },
    arrowIconStyle() {
      return {
        color: this.isHoveringArrow ? '#ffffff' : '#B9B9B9'
      }
    },
    isAddingLocation() {
      return this.$store.state.isAddingLocation
    },
    locationsList() {
      return this.filteredLocations ? this.filteredLocations : this.locations
    }
  },
  watch: {
    nextLicensePlateId(newValue) {
      this.setMoreLicencePlatesBtnState(!!newValue)
    },
    search() {
      this.nextLicensePlateId = null
    }
  },
  methods: {
    ...mapActions([
      'setSearchTabsShowing',
      'setLicencePlateParkingsList',
      'setMoreLicencePlatesBtnState'
    ]),
    addLocation() {
      this.$router.push({ name: 'AddLocation' })
    },
    switchToMerchant(loc) {
      this.$emit('switch-merchant', loc)
    },
    switchToChooseMerchant() {
      this.$emit('choose-merchants')
    },
    locationNeedsAttention(location) {
      return (
        location?.status_name === 'waiting_for_deletion' ||
        location?.status_name === 'pending'
      )
    },
    include() {
      const selectList = document.querySelector('.search-types-list')
      return selectList ? [selectList] : []
    },
    clickOutsideSearch() {
      this.searchActive = !!this.search
    },
    filterLocationsList() {
      if (
        !this.search ||
        this.searchType === 'name' ||
        this.searchType === 'id'
      ) {
        this.filterLocationsLocal(this.search.toString().toLowerCase())
      } else if (this.searchType === 'address') {
        this.searchByPinPoint()
      } else if (this.searchType === 'license_plate') {
        this.searchByLicensePlate()
      }
    },
    filterLocationsLocal(searchData) {
      this.filteredLocations = this.locations.filter((loc) => {
        return (
          loc[this.searchType]?.toString().toLowerCase().indexOf(searchData) !==
          -1
        )
      })
      this.$store.dispatch('setFilteredLocationsData', this.filteredLocations)
      this.setSearchTabsShowing(true)
    },
    updatePinPoint(pinPoint) {
      this.selectedPoint = pinPoint
    },
    async searchByPinPoint() {
      if (!this.selectedPoint) {
        this.$store.dispatch('clearFilteredLocationsData')
        this.filterLocationsLocal()
        return
      }
      const apiSettings = {
        method: 'get',
        service: 'merchant',
        url: 'admin/search',
        params: {
          search_field: 'pin_point',
          value: `${this.selectedPoint.latitude},${this.selectedPoint.longitude}`
        }
      }

      try {
        const response = await api.promise(apiSettings)
        this.$store.dispatch('setFilteredLocationsData', response.data)
        this.filteredLocations = response.data
        this.setSearchTabsShowing(true)
      } catch (error) {
        this.$store.dispatch('setSystemMessage', error)
      }
    },
    async searchByLicensePlate() {
      if (this.search?.length < 3) {
        this.$store.dispatch('setSystemMessage', {
          message: "The length of input value can't be less that 3 symbols."
        })
        return
      }

      if (this.showSearchTabs) {
        this.setSearchTabsShowing(false)
      }
      if (
        !this.searchByLicenceStartDate ||
        !this.searchByLicenceEndDate ||
        !this.search ||
        (this.sameWithPreviousParams(
          this.search,
          this.searchByLicenceStartDate,
          this.searchByLicenceEndDate
        ) &&
          !this.nextLicensePlateId)
      ) {
        return
      }
      const params = {
        search_field: 'license_plate',
        value: this.search,
        start_timestamp: moment(this.searchByLicenceStartDate, 'DD.MM.YYYY')
          .startOf('day')
          .unix(),
        end_timestamp: moment(this.searchByLicenceEndDate, 'DD.MM.YYYY')
          .endOf('day')
          .unix()
      }
      if (this.nextLicensePlateId) {
        params.next_id = this.nextLicensePlateId
      }
      const apiSettings = {
        method: 'get',
        service: 'merchant',
        url: 'admin/search',
        params
      }
      try {
        const response = await api.promise(apiSettings)
        if (
          this.nextLicensePlateId &&
          this.nextLicensePlateId !== response.data.next_id &&
          this.search === this.prevLicenseSearch
        ) {
          this.$store.dispatch(
            'addToLicencePlateParkingsList',
            response.data.parkings
          )
        } else {
          this.$store.dispatch(
            'setLicencePlateParkingsList',
            response.data.parkings
          )
        }
        this.nextLicensePlateId = response.data.next_id
        if (!this.nextLicensePlateId) {
          this.setMoreLicencePlatesBtnState(false)
        }
        const locations = this.searchedByLicencePlateList
          .filter((parking) => !!parking.location)
          .map((parking) => parking.location)
        const uniqueLocations = [
          ...new Map(locations.map((item) => [item.id, item])).values()
        ]
        this.filteredLocations = uniqueLocations
      } catch (error) {
        this.clearData()
        this.$store.dispatch('setSystemMessage', error)
      }
    },
    clearData() {
      this.$store.dispatch('setLicencePlateParkingsList', [])
      this.filteredLocations = []
      this.nextLicensePlateId = null
    },
    sameWithPreviousParams(search, startDate, endDate) {
      const comparison =
        this.prevLicenseSearch === search &&
        this.prevLicenseStart === startDate &&
        this.prevLicenseEnd === endDate
      if (!comparison) {
        this.prevLicenseSearch = search
        this.prevLicenseStart = startDate
        this.prevLicenseEnd = endDate
      }
      return comparison
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/sidebar_page';
.logo {
  height: 16px;
  width: 16px;
  color: rgba(225, 225, 225, 0.7) !important;
}
.search-area {
  position: absolute;
  top: 100px;
}

.all-merchants-logo {
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
}
.all-merchants-title {
  padding-bottom: 4px !important;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-top: 4px !important;
  padding-left: 14px !important;
}
.list-item-content {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.button {
  color: #fff !important;
  cursor: pointer;
  height: 36px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  letter-spacing: 0em !important;

  ::v-deep .button:hover {
    background-color: #222222 !important;
  }
}
.search-content-item-text {
  font-style: normal;
  font-weight: 400;
  &-title {
    font-size: 12px;
  }
  &-subtitle {
    font-size: 10px;
    color: #b9b9b9;
  }
}
::v-deep .button .custom-button:hover {
  background-color: #222222 !important;
}
.yellow-dot {
  display: flex;
  height: 10px;
  width: 10px;
  background-color: #ffa500;
  border-radius: 50%;
}
.locations-list {
  width: calc(100% - 35px);
  .link {
    width: 100%;
    .search-content-item-text {
      width: calc(100% - 28px);
      &-title {
        text-overflow: ellipsis;
        text-wrap: nowrap;
        overflow: hidden;
      }
    }
  }
}

/* Keep the top section fixed */
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 10;
}

/* Define the height and make the list scrollable */
.scrollable {
  max-height: 75vh;
  overflow-y: auto;
  scrollbar-width: none !important;
}


</style>
