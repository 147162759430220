<template>
  <div>
    <div class="content">
      <v-row class="row-item d-flex justify-space-between sticky-top">
        <v-btn text class="button--top text-capitalize button custom-button">
          <span class="button">
            {{ $t('title.choose_merchant') }}
          </span>
        </v-btn>
        <v-btn icon class="button arrow">
          <v-icon color="#B9B9B9"> mdi-chevron-down </v-icon>
        </v-btn>
      </v-row>

      <v-row class="search-content">
        <v-row class="row-item sticky-top">
          <v-text-field
            dark
            class="searchbox"
            placeholder="Search for merchant"
            name="query"
            v-model="search"
            style="
              padding-top: 0px !important;
              margin-top: 0px !important;
              color: rgba(225, 225, 225, 0.7);
            "
          >
            <template v-slot:prepend-inner>
              <v-icon class="logo" dense>mdi-magnify</v-icon>
            </template>
          </v-text-field>
        </v-row>
        <v-row class="merchants-list">
          <v-list flat class="list merchant-list">
            <v-list-item
              class="item add-merchant"
              v-show="auth.isParkmanAdmin()"
              :class="{ active: isAddingMerchant }"
              @click="addMerchant"
            >
              <v-list-item-content>
                <div class="list-item-content">
                  <v-list-item-title class="text">
                    <a class="link">
                      <img class="logo logo-add" src="@/assets/add-button.svg" />
                      {{ $t('link.adding_merchant') }}
                    </a>
                  </v-list-item-title>
                </div>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              class="item all-merchants"
              @click="switchToAllMerchants"
            >
              <v-list-item-content>
                <div class="list-item-content">
                  <v-list-item-title class="text">
                    <a class="link">
                      <img class="logo logo-add" src="@/assets/parkman-logo-small.png" />
                      {{ $t('link.all_merchants') }}
                    </a>
                  </v-list-item-title>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-row>
        <v-row class="merchants-list">
          <v-list flat class="list merchant-list scrollable">
            <v-list-item
              class="item"
              v-for="merchant in filteredMerchants"
              :class="{
                active: helpers.areSameId(merchant.id, selectedMerchantId)
              }"
              :data-id="merchant.id"
              @click="switchToMerchant(merchant)"
              :key="merchant.id"
            >
              <v-list-item-content>
                <v-list-item-title class="text">
                  <a class="link d-flex">
                    <img class="logo" :src="getMerchantLogo(merchant)" />
                    <span class="link-text">
                      {{ merchant.name }}
                    </span>
                  </a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-row>
      </v-row>

      <div class="sidebar-overlay" @click="didTapOnDarkener"></div>
    </div>
  </div>
</template>

<script>
import auth from '@/auth'
import helpers from '@/helpers'
import merchantHelpers from '@/helpers/merchant'
import defaultLogo from '@/assets/merchant-logo-placehoder.png'

export default {
  name: 'ChooseMerchantSidebar',
  data() {
    return {
      name: '',
      search: '',
      auth: auth,
      helpers: helpers,
      selectedMerchantId: -1,
      defaultLogo
    }
  },
  methods: {
    addMerchant() {
      this.$emit('add-merchant')
    },
    didTapOnDarkener() {
      if (this.$route.params.merchantId) {
        this.$emit('go-back')
      } else {
        this.switchToAllMerchants()
      }
    },
    switchToAllMerchants() {
      this.$emit('switch-all-merchants')
    },
    switchToMerchant(merchant) {
      this.selectedMerchantId = merchant.id
      this.$emit('switch-merchant', merchant.id)
    },
    getMerchantLogo(merchant) {
      return merchant.logo || defaultLogo
    }
  },
  computed: {
    filteredMerchants() {
      const merchants = this.$store.getters.merchants

      return merchants.filter((mer) => {
        return mer.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
      })
    },
    isAddingMerchant() {
      return this.$store.state.isAddingMerchant
    }
  },
  async mounted() {
    // Performance optimization to avoid redundant requests
    if (this.$store.getters.merchants.length === 0) {
      await merchantHelpers.fetchAllMerchants()
      await merchantHelpers.fetchMerchantLogoList()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/sidebar_page';

.arrow {
  padding-bottom: 4px !important;
  padding-left: 4px !important;
}
.button {
  color: #fff !important;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  letter-spacing: 0em !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  ::v-deep .button:hover {
    background-color: #222222 !important;
  }
}
::v-deep .button.custom-button:hover {
  background-color: #222222 !important;
}
.logo {
  color: rgba(225, 225, 225, 0.7) !important;
}

.logo-add {
  margin-right: 8px !important;
}
.list-item-content {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.merchant-list {
  height: 100% !important;
  overflow-y: auto;
  z-index: 2;
}
.sidebar-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent; /* Set the overlay background color here if needed */
  z-index: 1; /* Make sure the overlay is above other elements in the sidebar */
}
.merchants-list {
  width: 100%;
  .link {
    width: calc(100% - 28px);
    &-text {
      display: block;
      width: 100%;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      overflow: hidden;
    }
  }
}

/* Keep the top section fixed */
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 10;
}

/* Define the height and make the list scrollable */
.scrollable {
  max-height: 70vh;
  overflow-y: auto;
  scrollbar-width: none !important;
}
</style>
