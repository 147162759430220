<template>
  <v-app :key="$i18n.locale">
    <v-overlay
      color="rgba(225, 225, 225, 0.70)"
      z-index="999"
      :value="!$store.state.isPageReady || $store.state.showOverlay"
    >
      <v-progress-circular color="#37bd00" indeterminate size="128" />
    </v-overlay>
    <!-- note that the default v-snackbar timeout behavior is buggy. A custom fix is implemented in vuex -->
    <v-snackbar
      v-for="(systemMessage, index) in $store.state.systemMessage"
      :key="index"
      class="system-message__wrap"
      :value="systemMessage.message"
      color="grey lighten-4"
      :style="{ top: index * 70 + 'px' }"
      top
      light
      :timeout="-1"
    >
      <div
        class="snackbar-content"
        :class="`system-message--${systemMessage.type}`"
      >
        <img
          v-if="systemMessage.type === 'success'"
          class="icon"
          src="@/assets/check.png"
          alt="Icon"
        />
        <img
          v-if="systemMessage.type === 'warning'"
          class="icon"
          src="@/assets/info-yellow.svg"
          alt="Icon"
        />
        <img
          v-if="systemMessage.type === 'delete'"
          class="icon"
          src="@/assets/trash.png"
          alt="Icon"
        />
        <span>{{ systemMessage.message }}</span>
      </div>
      <v-btn
        class="system-message__close-button"
        color="grey darken-4"
        text
        @click="closeToast(index)"
      >
        <img src="@/assets/close-toast.png" />
      </v-btn>
    </v-snackbar>
    <router-view />
  </v-app>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import helpers from '@/helpers'
import * as config from '@/config/config'
import merchantHelpers from '@/helpers/merchant'
import userHelpers from '@/helpers/user'
import auth from '@/auth'

export default {
  name: 'ParkManBusiness',
  data() {
    return {
      isShowingMaintenanceNotice: false,
      helpers: helpers
    }
  },
  computed: {
    maintenanceNoticeContent() {
      if (helpers.isShowingIncomingMaintenanceNotice()) {
        // show maintenance schedule based on start and end time
        const ms = moment(process.env.VUE_APP_MAINTENANCE_START)
        const me = moment(process.env.VUE_APP_MAINTENANCE_END)

        return this.$t('prior_maintenance_notice', {
          start_time: ms.format('DD.MM.YYYY HH:mm'),
          end_time: ms.isSame(me, 'day')
            ? me.format('HH:mm')
            : me.format('DD.MM.YYYY HH:mm')
        })
      }

      if (helpers.isUnderMaintenance()) {
        return this.$t('maintenance_content')
      }

      return ''
    },
    isMobile() {
      return (
        document.documentElement.clientWidth <= config.SMALL_SCREEN_BREAKPOINT
      )
    },
    authenticated() {
      return true
      // return auth.loggedIn()
    },
    sidebarShowing() {
      return true
    }
  },
  methods: {
    async fetchUserData() {
      const userData = helpers.fetchStorage('user-data')
      if (userData) {
        await this.$store.dispatch('setUserData', userData)
      }
    },
    async closeToast(index) {
      this.$store.state.systemMessage.splice(index, 1)
    }
  },
  created() {
    // show / hide loader based on api call states
    axios.interceptors.request.use(
      (config) => {
        // TODO not the best approach. Need reworking.
        if (!config.disableLoader) {
          // when request still sending
          this.$store.dispatch('setPageReadyStatus', false)
        }
        return config
      },
      (error) => {
        // when request failed, hide loader
        this.$store.dispatch('setPageReadyStatus', true)
        return Promise.reject(error)
      }
    )

    axios.interceptors.response.use(
      (response) => {
        // when response ready, hide loader
        this.$store.dispatch('setPageReadyStatus', true)
        return response
      },
      (error) => {
        // when response ready and error returned, hide loader
        this.$store.dispatch('setPageReadyStatus', true)
        return Promise.reject(error)
      }
    )
  },
  async mounted() {
    this.isShowingMaintenanceNotice =
      helpers.isUnderMaintenance() ||
      helpers.isShowingIncomingMaintenanceNotice()
    if (auth.loggedIn()) {
      await userHelpers.fetchRoleOptions()
      await Promise.all([
        merchantHelpers.fetchCountries(),
        userHelpers.fetchAccountRoleOptions(),
        this.fetchUserData()
      ])
    }
  }
}
</script>

<style lang="scss">
@import '@/style/common';
@import '@/style/variables';
@import '@/style/responsive';
// @import "scss/element_ui_customize";

.v-application.maintenance {
  header,
  main,
  footer {
    pointer-events: none;
  }

  main {
    -webkit-filter: blur(2px);
    filter: blur(2px);
  }
}

.v-main {
  padding: 0 !important;
}

.system-message__wrap .v-snack__content {
  display: grid;
  grid-template-columns: 1fr 24px;
  column-gap: 24px;
  margin-left: 8px;
}

.system-message {
  // @include text-body;
  color: $color-brand;

  &--success {
    color: $color-brand;
  }

  &--warning {
    color: $color-warning;
  }

  &--error {
    color: $color-danger;
  }

  &--delete {
    color: $color-danger;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding-left: 16px;
  }
}

.system-message__button-wrap {
  display: flex;
  justify-content: flex-end;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding-right: 16px;
  }
}

.system-message__close-button {
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  min-width: unset !important;
}

.system-message__icon {
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  color: $color-danger;
  min-width: unset !important;
}

.snackbar-content {
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 500 !important;
  font-size: 16px !important;
  font-family: Roboto !important;
  line-height: 22.4px !important;
  word-wrap: break-word;
}

.icon {
  width: 24px;
  height: 24px;
}

.snackbar--delete {
  color: #eb5757;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 22.4px;
  word-wrap: break-word;
}
</style>
