import moment from 'moment'

import api from '@/helpers/api'
import helpers from '@/helpers'
import i18n from '@/plugins/i18n'
import store from '@/store'

export default {
  /**
   * "Translate" different way of calling payment method from backend side to frontend side for consistency
   * @param {String} backendKey
   * @return {String} key used in the frontend
   */
  translateBackend(backendKey) {
    // handle case inconsistency
    if (backendKey) {
      backendKey = backendKey.toLowerCase()
    }

    // handle naming inconsistency
    switch (backendKey) {
      case 'payment_cards':
      case 'payment_card':
      case 'card':
        return 'card'

      case 'email_invoices':
      case 'email_invoice':
        return 'email_invoice'

      case 'electronic_invoices':
      case 'electronic_invoice':
      case 'e_invoice':
        return 'e_invoice'

      case 'paper_invoices':
      case 'paper_invoice':
        return 'paper_invoice'

      case 'mobile_subscription_payment':
        return 'phone_bill'

      default:
        return null
    }
  },

  /**
   * Get the active payment method of a payment group
   * @param {Object} groupPaymentMethodObject: all available payment method of a specific group stored in the system
   * @return {Object} the object containing the active payment method
   */
  getActivePaymentMethod(groupPaymentMethodObject) {
    // original object from backend: { payment_cards: [card1, card2], email_invoices: [invoice1, invoice2] }
    // first, get the id of the active payment method
    var activeMethodId =
      groupPaymentMethodObject.default_account_payment_method_id
    if (!activeMethodId) {
      return null
    }

    // collect all available method into one array: [card1, card2, invoice1, invoice2]
    // add the key as frontend-used method name
    var allPaymentMethods = []
    Object.keys(groupPaymentMethodObject).forEach((methodKey) => {
      var methodList = groupPaymentMethodObject[methodKey]
      if (Array.isArray(methodList)) {
        methodList.forEach((method) => {
          method.method = this.translateBackend(methodKey)
          allPaymentMethods.push(method)
        })
      }
    })

    // find the method with the id matches the active payment method id
    // this id is called `profile_payment_method_id` from the backend side
    return allPaymentMethods.find(
      (method) => method.account_payment_method_id === activeMethodId
    )
  },
  /**
   * Fetch invoice sending fee from backend
   */
  async fetchInvoiceSendingFee(type, groupAccountId) {
    const apiSettings = {
      method: 'get',
      service: 'product',
      url: 'pricing/invoice_fee',
      params: {
        account_id: groupAccountId,
        invoice_fee_type: type
      }
    }

    try {
      const response = await api.promise(apiSettings)
      return response.invoice_fee
    } catch (error) {
      return null
    }
  },

  /**
   * Fetch all available pricing package for selected company
   * Compose a frontend-ready object from multiple entities that backend returns
   */
  async fetchCompanyPricingPackages(companyId) {
    const apiSettings = {
      method: 'get',
      service: 'product',
      url: `companies/${companyId}/subscriptions`,
      params: {
        include_deleted: true
      }
    }

    try {
      const response = await api.promise(apiSettings)
      if (!response.subscriptions) {
        return null
      }

      const result = response.subscriptions.map((subscription) => {
        const pricingPackage = subscription.package
        if (!pricingPackage.prices || pricingPackage.prices.length === 0) {
          throw new Error(
            `No price configured for package #${pricingPackage.package_id} ${pricingPackage.name}`
          )
        }

        const priceDetail = pricingPackage.prices.find(
          (price) => price.id === subscription.package_price_id
        )
        const currency = priceDetail.country.currencies[0].symbol

        if (priceDetail.pending_update) {
          priceDetail.pending_update.currency = currency
        }

        return {
          subscription_id: subscription.id,
          package_id: pricingPackage.package_id,
          price_id: priceDetail.id,
          service_fee_id: pricingPackage.type_data.id,
          company_id: companyId,
          name: priceDetail.name,
          start_date: pricingPackage.start_date,
          renewal_interval: priceDetail.renewal_interval,
          next_renewal_date: this.getNextPackageRenewalDate(priceDetail),
          currency: currency,
          service_fee: priceDetail.service_fee,
          service_fee_text: this.getFixedAmountText(
            priceDetail.service_fee,
            currency
          ),
          service_fee_percent: priceDetail.service_fee_percent,
          service_fee_percent_text: this.getPercentageText(
            priceDetail.service_fee_percent
          ),
          period_fee: priceDetail.cost, // fixed price per month / week / etc.
          period_fee_text: this.getFixedAmountText(priceDetail.cost, currency), // fixed price per month / week / etc.
          is_active: subscription.active_package,
          is_default: subscription.default_package,
          pending_update: priceDetail.pending_update,
          is_deleted: subscription.deleted_on !== null
        }
      })

      store.dispatch('setPricingData', result)
      return result
    } catch (error) {
      store.dispatch('setSystemMessage', error)
      return null
    }
  },

  /**
   * Get list of default country-level packages
   * @param {String} countryCode in lowercase
   */
  async getDefaultCountryLevelPackages(countryCode) {
    const apiSettings = {
      method: 'get',
      service: 'product',
      url: 'service_fees',
      params: {
        country: countryCode,
        is_custom: false,
        product_type: 'parking',
        owner_type: 'parkman',
        package_usage_type: 'company'
      }
    }

    try {
      const response = await api.promise(apiSettings)
      const result = response.service_fees.map((pricingPackage) => {
        if (!pricingPackage.prices || pricingPackage.prices.length === 0) {
          throw new Error(
            `No price configured for package #${pricingPackage.package_id} ${pricingPackage.name}`
          )
        }

        const priceDetail = pricingPackage.prices[0]
        const currency = priceDetail.country.currencies[0].symbol

        if (priceDetail.pending_update) {
          priceDetail.pending_update.currency = currency
        }

        return {
          package_id: pricingPackage.package_id,
          price_id: priceDetail.id,
          service_fee_id: pricingPackage.type_data.id,
          name: priceDetail.name,
          is_custom: pricingPackage.is_custom,
          start_date: pricingPackage.start_date,
          renewal_interval: priceDetail.renewal_interval,
          next_renewal_date: this.getNextPackageRenewalDate(priceDetail),
          currency: currency,
          service_fee: priceDetail.service_fee,
          service_fee_text: this.getFixedAmountText(
            priceDetail.service_fee,
            currency
          ),
          service_fee_percent: priceDetail.service_fee_percent,
          service_fee_percent_text: this.getPercentageText(
            priceDetail.service_fee_percent
          ),
          period_fee: priceDetail.cost, // fixed price per month / week / etc.
          period_fee_text: this.getFixedAmountText(priceDetail.cost, currency), // fixed price per month / week / etc.
          created_on: priceDetail.created_on, // use this combined with updated_on, renewal_interval, and current date to determine if the updated price not yet in effect
          updated_on: priceDetail.updated_on,
          pending_update: priceDetail.pending_update
        }
      })

      return result
    } catch (error) {
      throw new Error(error[0].message)
    }
  },

  /**
   * Now - all package charge a fixed fee monthly, so just get the first day of next month
   * Later - based on renewal_interval to decide the day
   * @param {*} price
   */
  getNextPackageRenewalDate(price) {
    switch (price.renewal_interval) {
      case 'first_day_of_the_month':
        return moment().add(1, 'month').startOf('month').format('DD.MM.YYYY')
      default:
        return `-renewal date ${price.renewal_interval} not supported-`
    }
  },

  getFixedAmountText(amount, currency) {
    return amount ? helpers.displayMoney(amount, currency, 2) : '-'
  },

  getPercentageText(amount) {
    return amount ? `${amount.toFixed(2)}%` : '-'
  },

  /**
   * Get package name by id
   * @param {Integer} packageId
   * @param {Boolean} isGettingPendingUpdate - default: false. Set to true to display pending update info
   * @returns
   */
  getPackageNameById(packageId, isGettingPendingUpdate) {
    const defaultValue = '-'
    const packageList = store.state.pricingData

    if (!packageList) {
      return defaultValue
    }

    const packageObj = packageList.find(
      (item) => item.subscription_id === packageId
    )
    if (!packageObj) {
      return defaultValue
    }

    if (isGettingPendingUpdate) {
      return packageObj.pending_update.name || '-'
    }

    return packageObj.name || '-'
  },

  /**
   * Generate package pricing description
   * @param {Integer} packageId
   * @param {Boolean} isGettingPendingUpdate - default: false. Set to true to display pending update info
   */
  getPackageDescriptionById(packageId, isGettingPendingUpdate) {
    const defaultValue = '-'
    const packageList = store.state.pricingData

    if (!packageList) {
      return defaultValue
    }

    const packageObj = packageList.find(
      (item) => item.subscription_id === packageId
    )
    if (!isGettingPendingUpdate) {
      return packageObj ? this.getPackageDescription(packageObj) : defaultValue
    }

    if (packageObj.pending_update) {
      return this.getPackageDescription(packageObj.pending_update)
    }
  },

  /**
   * Get pricing package description
   * @param {*} packageObj - pricing package object, consists of name, fixed fee, percentage fee & period fee
   */
  getPackageDescription(packageObj) {
    const finalTextParts = []
    // generate service fee per parking part
    const parkingFeeParts = []

    if (packageObj.service_fee) {
      parkingFeeParts.push(
        this.getFixedAmountText(packageObj.service_fee, packageObj.currency)
      )
    }

    if (packageObj.service_fee_percent) {
      parkingFeeParts.push(
        this.getPercentageText(packageObj.service_fee_percent)
      )
    }

    if (parkingFeeParts.length > 0) {
      finalTextParts.push(
        `${parkingFeeParts.join(' + ')} ${i18n.t('per_parking')}`
      )
    }

    // generate period fee part. Currently only support monthly fee
    const periodFee = packageObj.period_fee || packageObj.cost
    if (periodFee) {
      finalTextParts.push(
        `${this.getFixedAmountText(periodFee, packageObj.currency)} ${i18n.t(
          'per_month'
        )}`
      )
    }

    return finalTextParts.length === 0
      ? i18n.t('free_package')
      : finalTextParts.join(' & ')
  },

  /**
   * Add employee to company pricing package
   * @param {Integer} employeeProfileId
   * @param {Integer} companySubscriptionId
   */
  async addEmployeeToCompanyPackage(employeeProfileId, companySubscriptionId) {
    const apiSettings = {
      method: 'post',
      service: 'product',
      url: `profiles/${employeeProfileId}/subscriptions`,
      data: {
        account_package_id: companySubscriptionId,
        package_usage_type: 'company'
      }
    }

    try {
      await api.promise(apiSettings)
    } catch (error) {
      throw new Error(error[0].message)
    }
  },

  /**
   * Fetch the list of employee package, based on employee profile list
   * @param {Array} employeeProfileIds
   */
  async fetchEmployeePackageList(employeeProfileIds) {
    const employeeProfileStr = employeeProfileIds.join(',')

    const apiSettings = {
      method: 'get',
      service: 'product',
      url: 'profiles/list_subscriptions',
      params: {
        profile_ids: employeeProfileStr,
        type: 'service_fee'
      }
    }

    try {
      const response = await api.promise(apiSettings)
      return Object.values(response.subscriptions).map((item) => item[0])
    } catch (error) {
      throw new Error(error[0].message)
    }
  }
}
