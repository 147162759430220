<template>
  <div>
    <div class="content">
      <v-row class="row-item d-flex justify-space-between sticky-top">
        <v-btn
          text
          class="button--top text-capitalize button custom-button"
          @click="openMerchant"
        >
          <img :src="currentLogo" class="logo" />
          <span class="truncate-text">
            {{ sidebarName | truncate(15) }}
          </span>
        </v-btn>
        <v-btn
          icon
          v-if="merchantAmount > 1"
          class="button custom-button"
          @click="switchToChooseMerchant"
          @mouseover="isHoveringArrow = true"
          @mouseout="isHoveringArrow = false"
          :style="arrowButtonStyle"
        >
          <v-icon :style="arrowIconStyle"> mdi-chevron-down </v-icon>
        </v-btn>
      </v-row>

      <v-row
        class="search-content sticky-top"
        v-click-outside="{
          handler: clickOutsideSearch,
          include: include
        }"
      >
        <sidebar-search
          :searchActive="searchActive"
          @enableSearchActive="searchActive = true"
          @searchUpdated="search = $event"
          @searchTypeUpdated="searchType = $event"
          @filterLocationsList="filterLocationsList"
          @updatePinPoint="updatePinPoint"
        />
        <v-row class="locations-list">
          <v-list flat class="list">
            <v-list-item
              v-show="auth.isParkmanAdmin()"
              class="item add-location"
              :class="{ active: isAddingLocation }"
            >
              <v-list-item-content>
                <div>
                  <v-list-item-title class="text">
                    <a class="link" @click="addLocation">
                      <img class="logo logo-add" src="@/assets/add-button.svg" />
                      {{ $t('link.adding_location') }}
                    </a>
                  </v-list-item-title>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-row>
        <v-row class="locations-list">
          <v-list flat class="list scrollable">
            <v-list-item
              class="list-item location-list-item"
              v-for="location in locationsList"
              :data-id="location.id"
              :key="location.id"
            >
              <v-list-item-content>
                <div
                  @click="openLocation(location)"
                  class="item list-item-content--location"
                  :class="{
                    active:
                      helpers.areSameId(location.id, activeLocationId) &&
                      selectedProduct === null &&
                      isAddingLocation === false
                  }"
                >
                  <v-list-item-title class="text flex-container px-8">
                    <div class="link d-flex align-center">
                      <div class="search-content-item-icon">
                        <img class="logo" src="@/assets/surface.png" />
                      </div>
                      <div
                        class="search-content-item-text d-flex flex-column justify-start"
                      >
                        <span class="search-content-item-text-title">
                          {{ location.name }}
                        </span>
                        <span class="search-content-item-text-subtitle">
                          Location ID: {{ location.id }}
                        </span>
                      </div>
                    </div>

                    <div
                      class="d-flex justify-end"
                      style="padding-left: 12px; width: 100%"
                      v-if="
                        locationExpired(location) || locationInactive(location)
                      "
                    >
                      <span
                        v-if="locationNeedsAttention(location)"
                        class="location-expired location-expired--withYellowDot"
                        >Inactive
                      </span>
                      <span v-else class="location-expired">Inactive </span>
                    </div>
                    <div v-if="locationNeedsAttention(location)">
                      <span class="yellow-dot"></span>
                    </div>
                  </v-list-item-title>
                </div>
                <div
                  class="products-section"
                  v-if="helpers.areSameId(location.id, activeLocationId)"
                >
                  <v-list-item-group>
                    <v-list-item
                      class="item product-item"
                      v-for="product in products"
                      @click="selectProduct(product, location)"
                      :class="{ active: selectedProduct === product.id }"
                      :key="product.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title class="text">
                          <a class="link">
                            <span class="product-item-text-title d-block">
                              {{ product.name }}
                            </span>
                          </a>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-row>
      </v-row>
    </div>
  </div>
</template>

<script>
import SidebarSearch from '@/components/Sidebar/Merchant/SidebarSearch'
import helpers from '@/helpers'
import defaultLogo from '@/assets/landing_logo@2x.png'
import auth from '@/auth'
import api from '@/helpers/api'
import eventBus from '@/helpers/eventBus'
import moment from 'moment'
import merchantHelpers from '@/helpers/merchant'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MerchantSidebar',
  components: { SidebarSearch },
  props: {
    locations: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      search: '',
      helpers: helpers,
      isHoveringArrow: false,
      searchType: 'name',
      searchActive: false,
      products: [
        { id: 1, name: 'Visitor parking ' }
        // { id: 2, name: 'Car washing ' }
      ],
      auth: auth,
      filteredLocations: null,
      selectedPoint: null,
      nextLicensePlateId: null
    }
  },
  mounted() {
    eventBus.$on('loadMerchantLicencePlates', this.searchByLicensePlate)
    eventBus.$on('reloadMerchantLicencePlates', () => {
      if (this.this.search) {
        this.clearData()
        this.searchByLicensePlate()
      }
    })
    if (this.merchantAmount === 0) {
      merchantHelpers.fetchAllMerchants()
    }
  },
  beforeDestroy() {
    this.clearData()
    eventBus.$off('loadMerchantLicencePlates', this.searchByLicensePlate)
    eventBus.$off('reloadMerchantLicencePlates', () => {
      this.clearData()
      this.searchByLicensePlate()
    })
  },
  watch: {
    nextLicensePlateId(newValue) {
      this.setMoreLicencePlatesBtnState(!!newValue)
    },
    search() {
      this.nextLicensePlateId = null
    }
  },
  methods: {
    ...mapActions([
      'setLicencePlateParkingsList',
      'setMoreLicencePlatesBtnState',
      'clearFilteredLocationsData',
      'setFilteredLocationsData',
      'setSystemMessage',
      'clearCurrentProduct',
      'setCurrentProduct',
      'addToLicencePlateParkingsList'
    ]),
    addLocation() {
      this.$emit('add-location')
    },
    async openLocation(loc) {
      await this.clearCurrentProduct()
      this.$emit('open-location', loc)
    },
    switchToChooseMerchant() {
      this.$emit('choose-merchants')
    },
    openMerchant() {
      // TODO: convert location to merchant
      this.$router.push({ name: 'Merchant' })
    },
    locationNeedsAttention(location) {
      return (
        location?.status_name === 'waiting_for_deletion' ||
        location?.status_name === 'pending'
      )
    },
    locationInactive(location) {
      return location?.status_name === 'inactive'
    },
    selectProduct(product, location) {
      this.$emit('open-product', product, location)
      this.setCurrentProduct(product.id)
    },
    locationExpired(location) {
      if (location.visible_to_date) {
        const givenDate = new Date(location.visible_to_date)
        const currentDate = new Date()
        return givenDate < currentDate
      }
      return false
    },
    include() {
      const selectList = document.querySelector('.search-types-list')
      return selectList ? [selectList] : []
    },
    clickOutsideSearch() {
      this.searchActive = !!this.search
    },
    filterLocationsList() {
      if (
        !this.search ||
        this.searchType === 'name' ||
        this.searchType === 'id'
      ) {
        this.filterLocationsLocal(this.search.toString().toLowerCase())
      } else if (this.searchType === 'address') {
        this.searchByPinPoint()
      } else if (this.searchType === 'license_plate') {
        this.searchByLicensePlate()
      }
    },
    filterLocationsLocal(searchData) {
      this.filteredLocations = this.locations.filter((loc) => {
        return (
          loc[this.searchType]?.toString().toLowerCase().indexOf(searchData) !==
          -1
        )
      })
      this.setFilteredLocationsData(this.filteredLocations)
      eventBus.$emit('showSearchTab', 0)
    },
    updatePinPoint(pinPoint) {
      this.selectedPoint = pinPoint
    },
    async searchByPinPoint() {
      if (!this.selectedPoint) {
        this.filterLocationsLocal()
        this.clearFilteredLocationsData()
        return
      }
      const queryParams = {
        search_field: 'pin_point',
        value: `${this.selectedPoint.latitude},${this.selectedPoint.longitude}`
      }
      if (auth.isParkmanAdmin()) {
        queryParams.merchant_id = this.$route.params.merchantId
      }
      const apiSettings = {
        method: 'get',
        service: 'merchant',
        url: auth.isParkmanAdmin()
          ? 'admin/search'
          : `merchants/search/${this.$route.params.merchantId}`,
        params: queryParams
      }

      try {
        const response = await api.promise(apiSettings)
        this.setFilteredLocationsData(response.data)
        this.filteredLocations = response.data
        eventBus.$emit('showSearchTab', 0)
      } catch (error) {
        this.setSystemMessage(error)
      }
    },
    async searchByLicensePlate() {
      eventBus.$emit('showSearchTab', 1)
      if (!this.searchByLicenceStartDate || !this.searchByLicenceEndDate) {
        return
      }
      if (this.search.length < 3) {
        this.setSystemMessage("License plate must contain at least 3 symbols")
        return;
      }
      const queryParams = {
        search_field: 'license_plate',
        value: this.search,
        start_timestamp: moment(this.searchByLicenceStartDate, 'DD.MM.YYYY')
          .startOf('day')
          .unix(),
        end_timestamp: moment(this.searchByLicenceEndDate, 'DD.MM.YYYY')
          .endOf('day')
          .unix()
      }
      if (auth.isParkmanAdmin()) {
        queryParams.merchant_id = this.$route.params.merchantId
      }
      if (this.nextLicensePlateId) {
        queryParams.next_id = this.nextLicensePlateId
      }
      const apiSettings = {
        method: 'get',
        service: 'merchant',
        url: auth.isParkmanAdmin()
          ? 'admin/search'
          : `merchants/search/${this.$route.params.merchantId}`,
        params: queryParams
      }
      try {
        if (this.search) {
          const response = await api.promise(apiSettings)
          if (
            this.nextLicensePlateId &&
            this.nextLicensePlateId !== response.data.next_id
          ) {
            this.addToLicencePlateParkingsList(response.data.parkings)
          } else {
            this.setLicencePlateParkingsList(response.data.parkings)
          }
          this.nextLicensePlateId = response.data.next_id
          const locations = this.searchedByLicencePlateList
            .filter((parking) => !!parking.location)
            .map((parking) => parking.location)
          const uniqueLocations = [
            ...new Map(locations.map((item) => [item.id, item])).values()
          ]
          this.filteredLocations = uniqueLocations
        }
      } catch (error) {
        this.clearData()
        this.setSystemMessage(error)
      }
    },
    clearData() {
      this.setLicencePlateParkingsList([])
      this.filteredLocations = []
      this.nextLicensePlateId = null
    }
  },
  computed: {
    ...mapGetters([
      'searchedByLicencePlateList',
      'searchByLicenceStartDate',
      'searchByLicenceEndDate',
      'merchants',
      'currentProduct',
      'currentMerchant',
      'isAddingLocation'
    ]),
    selectedProduct() {
      return this.currentProduct
    },
    isProductPage() {
      return this.$route.name === 'AddVisitorParking'
    },
    merchantAmount() {
      return this.merchants.length
    },
    arrowButtonStyle() {
      return {
        'background-color': this.isHoveringArrow
          ? '#222222 !important'
          : 'transparent',
        width: '32px',
        height: '32px'
      }
    },
    arrowIconStyle() {
      return {
        color: this.isHoveringArrow ? '#ffffff' : '#B9B9B9'
      }
    },
    sidebarName() {
      if (this.currentMerchant) {
        return this.currentMerchant.name
      }
      return 'Loading...'
    },
    currentLogo() {
      if (this.currentMerchant) {
        return this.currentMerchant.logo || defaultLogo
      }
      return defaultLogo
    },
    activeLocationId() {
      return parseInt(this.$route.params.locationId)
    },
    locationsList() {
      return this.filteredLocations ? this.filteredLocations : this.locations
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/sidebar_page';
@import '~@/style/common';
@import '~@/style/variables';
.products-section {
  padding-top: 0px;
}
.product-item {
  min-height: 30px;
  height: 30px !important;
  padding-left: 48px;
}
.product-item.active {
  background-color: $color-sidebar-item-selected;
}
.product-item.active a {
  color: $color-parkman;
}
.row-item {
  flex-wrap: nowrap; /* Prevent content from wrapping to a new row */
}

.truncate-text {
  white-space: nowrap; /* Prevent text from wrapping to a new line */
  overflow: hidden; /* Hide any overflowing text */
  text-overflow: ellipsis; /* Show ellipsis (...) for truncated text */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.button {
  color: #fff !important;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  letter-spacing: 0em !important;
  ::v-deep .button:hover {
    background-color: #222222 !important;
  }
}
::v-deep .button.custom-button:hover {
  background-color: #222222 !important;
}
.item.add-location {
  cursor: pointer;
  &:before {
    background-image: url('../../../assets/landing_logo@2x.png');
  }
  :hover {
    background-color: transparent !important;
  }
}
.logo {
  color: rgba(225, 225, 225, 0.7) !important;
}
.logo-add {
  margin-right: 8px !important;
}
.list-item-content {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  &--location {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    padding-left: 16px;
  }
}
.v-list-item__content {
  padding: 0px;
}
.v-list-item__content > :not(:last-child) {
  margin-bottom: 0px;
}
.yellow-dot {
  display: flex;
  height: 10px;
  width: 10px;
  background-color: #ffa500 !important;
  border-radius: 50%;
  margin-right: 32px;
}
.location-expired {
  margin-right: 32px;
  background-color: $color-border-hover;
  color: $color-sidebar-item-selected;
  font-weight: bold;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  padding: 2px 8px;
  border-radius: 20px;
  display: inline;
  vertical-align: middle;
  &--withYellowDot {
    margin-right: 2px;
  }
}

.flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-item {
  flex-grow: 1;
}
.locations-list,
.products-section {
  width: 100%;
  .list-item-content--location,
  .link {
    width: calc(100% - 35px);
    .search-content-item-text,
    .product-item-text {
      width: calc(100% - 28px);
      font-style: normal;
      font-weight: 400;
      &-title {
        font-size: 12px;
      }
      &-subtitle {
        font-size: 10px;
        color: #b9b9b9;
      }
      &-title {
        text-overflow: ellipsis;
        text-wrap: nowrap;
        overflow: hidden;
      }
    }
  }
}

/* Keep the top section fixed */
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 10;
}

/* Define the height and make the list scrollable */
.scrollable {
  max-height: 75vh;
  overflow-y: auto;
  scrollbar-width: none !important;
}
</style>
