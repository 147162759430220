export const MAP_SETTINGS = {
  apiKey: 'AIzaSyDmm1pKEBNhJmaDlMhbhEAUmaddSbsUupg',
  zoomThreshold: 11,
  styles: [
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#a6a6a6'
        },
        {
          lightness: '0'
        }
      ]
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'on'
        }
      ]
    },
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'administrative',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'simplified'
        },
        {
          lightness: '-5'
        }
      ]
    },
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [
        {
          lightness: '20'
        }
      ]
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#f6f6f6'
        }
      ]
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#cddcd2'
        },
        {
          lightness: '30'
        }
      ]
    },
    {
      featureType: 'landscape.natural',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#f0f0f0'
        }
      ]
    },
    {
      featureType: 'landscape.natural',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'simplified'
        },
        {
          lightness: '-10'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'on'
        },
        {
          color: '#d7ebd3'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#fcfcfc'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#e8e8e8'
        },
        {
          visibility: 'on'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'transit.station.airport',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'on'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#b4dafb'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#81879a'
        },
        {
          lightness: '20'
        },
        {
          saturation: '-60'
        }
      ]
    }
  ]
  // old map styles
  //
  // styles: [
  //   {
  //     featureType: 'all',
  //     elementType: 'labels.text.fill',
  //     stylers: [
  //       {
  //         color: '#000000'
  //       }
  //     ]
  //   },
  //   {
  //     featureType: 'all',
  //     elementType: 'labels.text.stroke',
  //     stylers: [
  //       {
  //         color: '#FFFFFF'
  //       }
  //     ]
  //   },
  //   {
  //     featureType: 'administrative.province',
  //     elementType: 'all',
  //     stylers: [
  //       {
  //         visibility: 'on'
  //       }
  //     ]
  //   },
  //   {
  //     featureType: 'landscape',
  //     elementType: 'all',
  //     stylers: [
  //       {
  //         saturation: '-39'
  //       },
  //       {
  //         lightness: '35'
  //       },
  //       {
  //         gamma: '1.00'
  //       }
  //     ]
  //   },
  //   {
  //     featureType: 'landscape',
  //     elementType: 'geometry',
  //     stylers: [
  //       {
  //         saturation: '0'
  //       }
  //     ]
  //   },
  //   {
  //     featureType: 'landscape.man_made',
  //     elementType: 'all',
  //     stylers: [
  //       {
  //         saturation: '-100'
  //       },
  //       {
  //         lightness: '10'
  //       }
  //     ]
  //   },
  //   {
  //     featureType: 'landscape.man_made',
  //     elementType: 'geometry.stroke',
  //     stylers: [
  //       {
  //         saturation: '-100'
  //       },
  //       {
  //         lightness: '-14'
  //       }
  //     ]
  //   },
  //   {
  //     featureType: 'poi',
  //     elementType: 'all',
  //     stylers: [
  //       {
  //         saturation: '-100'
  //       },
  //       {
  //         lightness: '10'
  //       },
  //       {
  //         gamma: '2.26'
  //       },
  //       {
  //         visibility: 'off'
  //       }
  //     ]
  //   },
  //   {
  //     featureType: 'poi',
  //     elementType: 'labels.text',
  //     stylers: [
  //       {
  //         saturation: '-100'
  //       },
  //       {
  //         lightness: '-3'
  //       }
  //     ]
  //   },
  //   {
  //     featureType: 'road',
  //     elementType: 'all',
  //     stylers: [
  //       {
  //         saturation: '-100'
  //       },
  //       {
  //         lightness: '54'
  //       }
  //     ]
  //   },
  //   {
  //     featureType: 'road',
  //     elementType: 'geometry.stroke',
  //     stylers: [
  //       {
  //         saturation: '-100'
  //       },
  //       {
  //         lightness: '-7'
  //       }
  //     ]
  //   },
  //   {
  //     featureType: 'road.arterial',
  //     elementType: 'all',
  //     stylers: [
  //       {
  //         saturation: '-100'
  //       }
  //     ]
  //   },
  //   {
  //     featureType: 'road.local',
  //     elementType: 'all',
  //     stylers: [
  //       {
  //         saturation: '-100'
  //       },
  //       {
  //         lightness: '-2'
  //       }
  //     ]
  //   },
  //   {
  //     featureType: 'transit',
  //     elementType: 'all',
  //     stylers: [
  //       {
  //         saturation: '-100'
  //       }
  //     ]
  //   },
  //   {
  //     featureType: 'water',
  //     elementType: 'geometry.fill',
  //     stylers: [
  //       {
  //         saturation: '-100'
  //       },
  //       {
  //         lightness: '100'
  //       }
  //     ]
  //   },
  //   {
  //     featureType: 'water',
  //     elementType: 'geometry.stroke',
  //     stylers: [
  //       {
  //         saturation: '-100'
  //       },
  //       {
  //         lightness: '-100'
  //       }
  //     ]
  //   }
  // ]
}

export const GOOGLE_API_KEYS = {
  DEV: 'AIzaSyDmm1pKEBNhJmaDlMhbhEAUmaddSbsUupg',
  TEST: 'AIzaSyBhG4hsh71BldrkuKMWwaRRwY6CXBi78Mc',
  PROD: 'AIzaSyCKWFV4DH3RqhRXmjPyOY_oEINk0Zx6j4Q'
}
