import api from '@/helpers/api'
import auth from '@/auth'
import helpers from '@/helpers'
import i18n from '@/plugins/i18n'
import paymentHelpers from '@/helpers/payment'
import store from '@/store'

export default {
  /**
   * Fetch company info and store in vuex
   */
  async fetchCompanyInfo(companyId) {
    store.dispatch('setSystemMessage', {
      message: `${i18n.t('loading_company')}...`,
      type: 'success'
    })

    const apiSettings = {
      method: 'get',
      service: 'users',
      url: `v1/companies/${companyId}`
    }

    try {
      const response = await api.promise(apiSettings)
      const company = response.companies

      helpers.saveStorage('company', company.id)
      store.dispatch('setCompanyData', company)
      store.dispatch('setSystemMessage', null)

      return company
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  /**
   * Fetch the options of payment methods, based on the company's country
   */
  async fetchPaymentOptions(countryCode) {
    store.dispatch('setSystemMessage', {
      message: `${i18n.t('loading_payment_options')}...`,
      type: 'success'
    })

    const apiSettings = {
      method: 'get',
      service: 'billing',
      url: 'support_payment_methods%2F',
      params: {
        country: countryCode,
        payer_type: 'group'
      }
    }

    try {
      const response = await api.promise(apiSettings)
      const paymentOptions = response.payment_methods.map((method) =>
        paymentHelpers.translateBackend(method.payment_method_name)
      )

      store.dispatch('setSystemMessage', null)
      store.dispatch('setPaymentOptions', paymentOptions)
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  /**
   * Fetch the payment methods currently assigned to each group
   */
  async fetchCurrentGroupPaymentMethod(groups) {
    const groupAccountIds = groups.map((group) => group.account_id).join('|')

    store.dispatch('setSystemMessage', {
      message: `${i18n.t('loading_payment')}...`,
      type: 'success'
    })

    const apiSettings = {
      method: 'get',
      service: 'billing',
      url: 'account_payment_methods/list_default',
      params: {
        account_ids: groupAccountIds
      }
    }

    try {
      const response = await api.promise(apiSettings)
      store.dispatch('setSystemMessage', null)
      return response.account_default_payment_methods
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  /**
   * Fetch the vehicle whitelist of each group in the company
   * @param {Interger} companyId
   */
  async fetchCompanyVehicles(companyId) {
    const apiSettings = {
      method: 'get',
      service: 'users',
      url: `v1/companies/${companyId}/cars`
    }

    try {
      const response = await api.promise(apiSettings)
      const vehicles = response.cars
      store.dispatch('setVehicleData', vehicles)
      return vehicles
    } catch (error) {
      store.dispatch('setSystemMessage', error)
    }
  },
  /**
   * Check each group in {company}, and assign the active payment method to the group with matching profile id
   * @param {Array} groups list of groups belong to the company
   * @param {Array} availablePaymentMethods list of active payment method for each group
   */
  setGroupActivePaymentMethod(groups, currentGroupPaymentMethods) {
    if (!groups || !currentGroupPaymentMethods) {
      return
    }

    currentGroupPaymentMethods.forEach((paymentMethod) => {
      // assign the active payment method to the group with matching profile id
      var group = groups.find(
        (profile) => profile.account_id === paymentMethod.account_id
      )
      if (group) {
        group.active_payment_method = paymentHelpers.translateBackend(
          paymentMethod.payment_method_name
        )
        // pass also card masked pan if payment method is card
        group.card_masked_pan = paymentMethod.masked_pan
        group.order_ref = paymentMethod.order_ref
      }
    })
  },
  /**
   * Fetch all groups belong to the company
   */
  async fetchCompanyGroups(companyId) {
    store.dispatch('setSystemMessage', {
      message: `${i18n.t('loading_company')}...`,
      type: 'success'
    })

    const apiSettings = {
      method: 'get',
      service: 'users',
      url: `v1/companies/${companyId}/groups`
    }

    try {
      const response = await api.promise(apiSettings)
      let groups = response.groups

      // In the case of group admin, return only the group (s)he belongs to
      // @todo: come back to this when one user can manage multiple groups
      if (auth.isGroupAdmin()) {
        groups = groups.filter(
          (group) => group.id === parseInt(helpers.fetchStorage('group-id'))
        )
      }

      store.dispatch('setGroupData', groups)
      store.dispatch('setSystemMessage', null)

      return groups
    } catch (error) {
      store.dispatch('setSystemMessage', error)
      store.dispatch('setGroupData', [])
      return []
    }
  },

  /**
   * Get the default group id for pre-selection
   * @param {Array} groups - list of groups belong to a company
   * @return {Int} defaultGroupId
   */
  getCompanyDefaultGroupId(groups) {
    // Group admin case
    // @todo: come back to this when one user can manage multiple groups
    if (auth.isGroupAdmin()) {
      return parseInt(helpers.fetchStorage('group-id'))
    }
    // company admin case
    const groupIds = groups.map((group) => group.id)
    return Math.min(...groupIds)
  },

  /**
   * From company data, prepare a list for group selection dropdown
   */
  buildGroupOptions() {
    try {
      // get group list from company
      const groups = store.state.groupData

      if (groups) {
        // build the list
        return groups.map((group) => {
          // take group name & id
          return {
            value: group.id,
            text: group.name
          }
        })
      }
    } catch (error) {
      return null
    }
  },
  /**
   * Get group name by its id
   * @param {Integer} groupId
   */
  getGroupNameById(groupId, groupList) {
    if (!groupId || !groupList) {
      return null
    }

    const group = groupList.find((group) => group.id === groupId)

    return group ? group.name : null
  },

  getGroupById(groupId) {
    if (!groupId) {
      return null
    }

    return store.state.groupData.find((group) => group.id === groupId)
  },

  getGroupByAccountId(accountId) {
    const group = store.state.groupData.find(
      (group) => group.account_id === accountId
    )

    return group
  },

  /**
   * Get group vehicle object from the company vehicle list
   * @param {Integer} groupId
   * @param {Array} vehicleList
   */
  getGroupVehicles(groupId, vehicleList) {
    if (!groupId || !vehicleList) {
      return null
    }

    return vehicleList.find((item) => item.group_id === groupId)
  },

  /**
   * Check if there is at least one company which the user belongs to enterprise admin group
   * @param {*} companies - list of companies which the user belongs
   */
  hasCompanyWithEnterpriseAdminRole(companies) {
    return (
      companies.filter((company) => company.enterprise_admin === true).length >=
      1
    )
  }
}
